

export default function TimestampToString() {


    function timeStampToDDMMAAAA(timestamp) {


        if (timestamp) {
            let aaaa = timestamp.slice(0, 4)
            let mm = timestamp.slice(5, 7)
            let dd = timestamp.slice(8, 10)


            return `${dd}/${mm}/${aaaa}`
        }



    }

    function numberToMonth(n) {

        switch (n) {
            case '01':
                return 'Janeiro';

            case '02':
                return 'Fevereiro';

            case '03':
                return 'Março';

            case '04':
                return 'Abril';

            case '05':
                return 'Maio';

            case '06':
                return 'Junho';

            case '07':
                return 'Julho';

            case '08':
                return 'Agosto';

            case '09':
                return 'Setembro';

            case '10':
                return 'Outubro';

            case '11':
                return 'Novembro';

            case '12':
                return 'Dezembro';
            default:
                return '';

        }
    }

    function timeStampToDateString(timestamp) {

        if (timestamp) {
            let aaaa = timestamp.slice(0, 4)
            let mm = timestamp.slice(5, 7)
            let dd = timestamp.slice(8, 10)


            return `Rio de Janeiro, ${dd} de ${numberToMonth(mm)} de ${aaaa}`
        }

    }

    return {timeStampToDDMMAAAA, numberToMonth, timeStampToDateString}
}
