import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import Select from 'react-select'


import './formItem.css'
import Input from '../../../shared/components/form/input'

//functions
import NumberToMoney from '../../../shared/functions/NumberToMoney'
import RandomString from '../../../shared/functions/RandomString'


function FormCategoryItem({ handleUpdate, data }) {

    const categoryItemDefault = {
        itemCode: "",
        description: "",
        color: "",
        ref: "",
        labor: "0.00",
        cost: "0.00",
        inCash: "70",
        instalment: "80",
        percentOfSale: "100",
        unit: { label: "Metro", name: "unit", value: "m" },
        measure: "1",
        sex: { name: "sex", value: "M", label: "Masculino" },

    }

    const finalPriceDefault = {
        inCashPrice: "0.00",
        instalmentPrice: "0.00"
    }
    const [CategoryItemArray, setCategoryItemArray] = useState(data || [])
    const [modalItem, setModalItem] = useState('modal-display-none')
    const [modalItemEditData, setModalItemEditData] = useState("")
    const [modalItemEdit, setModalItemEdit] = useState('modal-display-none')
    const [modalCategoryItemDelete, setModalCategoryItemDelete] = useState('modal-display-none')
    const [categoryItemDelete, setCategoryItemDelete] = useState(categoryItemDefault)
    const [categoryItem, setCategoryItem] = useState(data || categoryItemDefault)
    const [finalPrice, setFinalPrice] = useState(categoryItem.inCashPrice || finalPriceDefault)
    const [editItemData, setEditItemData] = useState("")

    let inCashPrice
    let instalmentPrice
    console.log("itemmmmmmmm")
    console.log(modalItemEditData)
    useEffect(() => {
        if (categoryItem.measure) {
            inCashPrice = finalPriceCalc(categoryItem.measure, categoryItem.labor, categoryItem.cost, categoryItem.inCash, categoryItem.percentOfSale)
            if (inCashPrice.toFixed(2) !== parseFloat(finalPrice.inCashPrice).toFixed(2)) {

                let inCashPrice2 = inCashPrice.toFixed(2)
                inCashPrice2 = '' + inCashPrice2

                setFinalPrice({ ...finalPrice, inCashPrice: inCashPrice2 })
            }

            instalmentPrice = finalPriceCalc(categoryItem.measure, categoryItem.labor, categoryItem.cost, categoryItem.instalment, categoryItem.percentOfSale)
            if (instalmentPrice.toFixed(2) !== parseFloat(finalPrice.instalmentPrice).toFixed(2)) {

                let instalmentPrice2 = instalmentPrice.toFixed(2)
                instalmentPrice2 = '' + instalmentPrice2

                setFinalPrice({ ...finalPrice, instalmentPrice: instalmentPrice2 })
            }
            console.log("in cash price")
            console.log(inCashPrice)
        }
    })
    function handleChange(e) {

        setCategoryItem({ ...categoryItem, [e.target.name]: e.target.value })

    }
    function handleChangeEdit(e) {
        setModalItemEditData({ ...modalItemEditData, [e.target.name]: e.target.value })


    }
    function handleOnSubmit() {
        let data = categoryItem
        let id = RandomString(16)
        data = { ...data, _id: id, inCashPrice: finalPrice.inCashPrice, instalmentPrice: finalPrice.instalmentPrice }
        
        let array = [...CategoryItemArray, data]
        setCategoryItemArray(array)
        handleUpdate(array)
        setModalItem("modal-display-none")

    }
    function saveEditItem() {
        let id = modalItemEditData._id

        let array = CategoryItemArray.map((i) => {
            if (i._id === id) {
                return modalItemEditData
            } else {
                return i
            }
        })
        setCategoryItemArray(array)
        console.log(" array update haha")
        console.log(array)
        handleUpdate(array)
        setModalItemEdit("modal-display-none")

    }
    function removeCategoryItem(data) {
        setModalCategoryItemDelete("modal-display-flex")
        setCategoryItemDelete(data)

    }
    function deleteCategoryItem(){
        const updatedArray = CategoryItemArray.filter((i) => {
            return i._id !== categoryItemDelete._id
        })
        
        setCategoryItemArray(updatedArray)
        handleUpdate(updatedArray)
        setModalCategoryItemDelete("modal-display-none")
    

    }
    const handleChangeItem = (e) => {

        setEditItemData({ ...editItemData, [e.target.name]: e.target.value })
    }
    function editItem(item) {
        setModalItemEditData(item)
        setModalItemEdit("modal-display-flex")

    }
    function addItem() {
        setCategoryItem(categoryItemDefault)
        setModalItem("modal-display-flex")

    }
    function handleChangeMoney(e) {

        let value = NumberToMoney(e.target.value)

        setCategoryItem({ ...categoryItem, [e.target.name]: value })


    }
    function finalPriceCalc(measure, labor, cost, percent, percentOfSale) {

        let price
        if (categoryItem.unit.value === "kg") {
            
            price = (((parseFloat(cost) / parseFloat(measure)) + parseFloat(labor)) * (1 + parseFloat(percent) / 100)) * (1 + parseFloat(percentOfSale) / 100)

        } if (categoryItem.unit.value === "m") {
            
            price = (((parseFloat(cost) * parseFloat(measure)) + parseFloat(labor)) * (1 + parseFloat(percent) / 100)) * (1 + parseFloat(percentOfSale) / 100)

            /*  price = ((parseFloat(labor) + parseFloat(cost)) *(1 + parseFloat(percent)/100) )*(1 + parseFloat(percentOfSale)/100) */
        }

       


        return price

    }
    const handleSelectChange = (selectedOption) => {

       
        setCategoryItem({ ...categoryItem, [selectedOption.name]: selectedOption })
    }
    const selectStyles = {
        control: (styles) => ({ ...styles, borderColor: "black", borderRadius: "10px", border: "1px solid #9e9e9e", height: "41px" }),
    };
    const optionsSex = [
        { name: "sex", value: "M", label: "Masculino" },
        { name: "sex", value: "F", label: "Feminino" },
    ]
    const optionsUnit = [
        { name: "unit", value: "m", label: "Metro" },
        { name: "unit", value: "kg", label: "Quilo" },
    ]
    return (
        <section className="box-itens">

            <table>
                <thead className="box-itens-thead">
                    <tr>
                        <th>Código</th>
                        <th>Descrição</th>
                        <th>Cor</th>
                        <th>Ref.</th>
                        <th>Sexo</th>
                        <th>Custo</th>
                        <th>Medida</th>
                        <th>Uni.</th>
                        <th>M. de Obra</th>
                        <th>Á vista</th>
                        <th>Parcelado</th>
                        <th>% de venda</th>
                        <th colSpan="2">Valor</th>
                        <th></th>
                        <th></th>

                    </tr>
                    <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>Á vista</th>
                        <th>Parcelado</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody className="box-itens-tbody">
                    {CategoryItemArray &&
                        CategoryItemArray.map((x) =>
                            <tr>
                                <td > <div className="box-itens-tbody-td">{x.itemCode}</div>  </td>
                                <td > <div className="box-itens-tbody-td">{x.description}</div>  </td>
                                <td > <div className="box-itens-tbody-td">{x.color}</div>  </td>
                                <td > <div className="box-itens-tbody-td">{x.ref}</div>  </td>

                                <td > <div className="box-itens-tbody-td">{x.sex.value}</div>  </td>

                                <td > <div className="box-itens-tbody-td">{x.cost}</div>  </td>
                                <td > <div className="box-itens-tbody-td">{x.measure}</div>  </td>
                                <td > <div className="box-itens-tbody-td">{x.unit.value}</div>  </td>
                                <td > <div className="box-itens-tbody-td">{x.labor}</div>  </td>
                                <td > <div className="box-itens-tbody-td">{x.inCash}</div>  </td>
                                <td > <div className="box-itens-tbody-td">{x.instalment}</div>  </td>
                                <td > <div className="box-itens-tbody-td">{x.percentOfSale}</div>  </td>
                                <td > <div className="box-itens-tbody-td">R$ {x.inCashPrice}</div>  </td>
                                <td > <div className="box-itens-tbody-td">R$ {x.instalmentPrice}</div>  </td>
                                <td ><div className=' box-itens-tbody-td-action'>
                                    <div className="bt edit" title="editar" onClick={() => { editItem(x) }}></div>
                                    <div className='bt delete' title='Apagar' onClick={() => { removeCategoryItem(x) }}></div>

                                </div></td>
                            </tr>

                        )

                    }

                </tbody>
            </table>
            <div className="box-itens-bt">
                <div className="box-itens-bt-add bt-model-add " onClick={() => { addItem() }}> Adicionar Item</div>
            </div>
            {/*ITEM */}
            <div className={`category-modal-background ${modalItem} `} >
                <div className="category-modal-item">
                    <div className="box-x"> <div onClick={() => { setModalItem("modal-display-none") }} className="x">X</div></div>

                    <section className='modal-section-item'>
                        <div className='modal-section-input-item'>
                            <div className='input-m-3 form-group'>
                                <Input
                                    text="Codigo"
                                    type="number"
                                    name="itemCode"
                                    handleOnChange={handleChange}
                                    value={categoryItem.itemCode || ""}
                                />
                            </div>
                            <div className='input-m-1 form-group'>
                                <Input
                                    text="Descrição"
                                    type="text"
                                    name="description"
                                    handleOnChange={handleChange}
                                    value={categoryItem.description || ""}
                                />
                            </div>
                            <div className='input-m-2 form-group'>
                                <Input
                                    text="Cor"
                                    type="text"
                                    name="color"
                                    handleOnChange={handleChange}
                                    value={categoryItem.color || ""}
                                />
                            </div>
                            <div className='input-m-3 form-group'>
                                <Input
                                    text="Referência"
                                    type="text"
                                    name="ref"
                                    handleOnChange={handleChange}
                                    value={categoryItem.ref || ""}
                                />
                            </div>
                            <div className="input-m-3 form-group">
                                <label className="input-label">Sexo:

                                </label>
                                <Select
                                    options={optionsSex}
                                    styles={selectStyles}
                                    onChange={handleSelectChange}
                                    value={categoryItem.sex || categoryItemDefault.sex}

                                />
                            </div>
                            <div className='input-m-3 form-group'>
                                <Input
                                    text="Custo"
                                    type="number"
                                    name="cost"
                                    handleOnChange={handleChangeMoney}
                                    value={categoryItem.cost || "0.00"}
                                />
                            </div>

                            <div className='input-m-3 form-group'>
                                <Input
                                    text="Medida"
                                    type="number"
                                    name="measure"
                                    handleOnChange={handleChange}
                                    value={categoryItem.measure || ""}
                                />
                            </div>

                            <div className="input-m-3 form-group">
                                <label className="input-label">Unidade:

                                </label>
                                <Select
                                    options={optionsUnit}
                                    styles={selectStyles}
                                    onChange={handleSelectChange}
                                    value={categoryItem.unit || categoryItemDefault.unit}

                                />
                            </div>

                            <div className='input-m-3 form-group'>
                                <Input
                                    text="Mão de Obra"
                                    type="number"
                                    name="labor"
                                    handleOnChange={handleChangeMoney}
                                    value={categoryItem.labor || "0.00"}
                                />
                            </div>

                            <div className='input-m-3 form-group'>
                                <Input
                                    text="% Á Vista"
                                    type="number"
                                    name="inCash"
                                    handleOnChange={handleChange}
                                    value={categoryItem.inCash || ""}
                                />
                            </div>
                            <div className='input-m-3 form-group'>
                                <Input
                                    text="% Parcelado"
                                    type="number"
                                    name="instalment"
                                    handleOnChange={handleChange}
                                    value={categoryItem.instalment || ""}
                                />
                            </div>
                            <div className='input-m-3 form-group'>
                                <Input
                                    text="% de venda"
                                    type="number"
                                    name="percentOfSale"
                                    handleOnChange={handleChange}
                                    value={categoryItem.percentOfSale || ""}
                                />
                            </div>
                            <div className='div-final-price'>
                                <div className='input-m-3 form-group'>
                                    <label>Á vista</label>
                                    <div className='input-block'> R$ {finalPrice.inCashPrice}</div>
                                </div>
                                <div className='input-m-3 form-group'>
                                    <label>Parcelado</label>
                                    <div className='input-block'> R$ {finalPrice.instalmentPrice}</div>
                                </div>
                            </div>


                        </div>

                        <div className='form-group-m1 form-group-right '>
                            <div className='bt-model-add bt-width-m2 bt-model-2 ' onClick={() => { handleOnSubmit() }}>Salvar</div>
                        </div>
                    </section>


                </div>

            </div >





            {/* MODAL ITEM EDIT*/}
            <div className={`category-modal-background ${modalItemEdit} `} >
                <div className="category-modal-item">
                    <div className="box-x"> <div onClick={() => { setModalItemEdit("modal-display-none") }} className="x">X</div></div>

                    <section className='modal-section-item'>
                        <div className='modal-section-input-item'>
                            <div className='input-m-3 form-group'>
                                <Input
                                    text="Codigo"
                                    type="number"
                                    name="itemCode"
                                    handleOnChange={handleChangeEdit}
                                    value={modalItemEditData.itemCode |""}
                                />
                            </div>
                            <div className='input-m-1 form-group'>
                                <Input
                                    text="Descrição"
                                    type="text"
                                    name="description"
                                    handleOnChange={handleChangeEdit}
                                    value={modalItemEditData.description || ""}
                                />
                            </div>
                            <div className='input-m-2 form-group'>
                                <Input
                                    text="Cor"
                                    type="text"
                                    name="color"
                                    handleOnChange={handleChangeEdit}
                                    value={modalItemEditData.color || ""}
                                />
                            </div>
                            <div className='input-m-3 form-group'>
                                <Input
                                    text="Referência"
                                    type="text"
                                    name="ref"
                                    handleOnChange={handleChangeEdit}
                                    value={modalItemEditData.ref || ""}
                                />
                            </div>
                            <div className="input-m-3 form-group">
                                <label className="input-label">Sexo:

                                </label>
                                <Select
                                    options={optionsSex}
                                    styles={selectStyles}
                                    onChange={handleSelectChange}
                                    value={modalItemEditData.sex || categoryItemDefault.sex}

                                />
                            </div>
                            <div className='input-m-3 form-group'>
                                <Input
                                    text="Custo"
                                    type="number"
                                    name="cost"
                                    handleOnChange={handleChangeMoney}
                                    value={modalItemEditData.cost || "0.00"}
                                />
                            </div>

                            <div className='input-m-3 form-group'>
                                <Input
                                    text="Medida"
                                    type="number"
                                    name="measure"
                                    handleOnChange={handleChangeEdit}
                                    value={modalItemEditData.measure || ""}
                                />
                            </div>

                            <div className="input-m-3 form-group">
                                <label className="input-label">Unidade:

                                </label>
                                <Select
                                    options={optionsUnit}
                                    styles={selectStyles}
                                    onChange={handleSelectChange}
                                    value={modalItemEditData.unit || categoryItemDefault.unit}

                                />
                            </div>

                            <div className='input-m-3 form-group'>
                                <Input
                                    text="Mão de Obra"
                                    type="number"
                                    name="labor"
                                    handleOnChange={handleChangeMoney}
                                    value={modalItemEditData.labor || "0.00"}
                                />
                            </div>

                            <div className='input-m-3 form-group'>
                                <Input
                                    text="% Á Vista"
                                    type="number"
                                    name="inCash"
                                    handleOnChange={handleChangeEdit}
                                    value={modalItemEditData.inCash || ""}
                                />
                            </div>
                            <div className='input-m-3 form-group'>
                                <Input
                                    text="% Parcelado"
                                    type="number"
                                    name="instalment"
                                    handleOnChange={handleChangeEdit}
                                    value={modalItemEditData.instalment || ""}
                                />
                            </div>
                            <div className='input-m-3 form-group'>
                                <Input
                                    text="% de venda"
                                    type="number"
                                    name="percentOfSale"
                                    handleOnChange={handleChangeEdit}
                                    value={modalItemEditData.percentOfSale || ""}
                                />
                            </div>
                            <div className='div-final-price'>
                                <div className='input-m-3 form-group'>
                                    <label>Á vista</label>
                                    <div className='input-block'> R$ {finalPrice.inCashPrice}</div>
                                </div>
                                <div className='input-m-3 form-group'>
                                    <label>Parcelado</label>
                                    <div className='input-block'> R$ {finalPrice.instalmentPrice}</div>
                                </div>
                            </div>


                        </div>

                        <div className='form-group-m1 form-group-right '>
                            <div className='bt-model-add bt-width-m2 bt-model-2 ' onClick={() => { saveEditItem() }}>Salvar</div>
                        </div>
                    </section>


                </div>

            </div >

            {/* CONFIRM DELETE MODAL*/}
            <div className={`category-modal-background ${modalCategoryItemDelete} `} >
                <div className="category-modal-category-item">
                    <div className="box-x"> <div onClick={() => { setModalCategoryItemDelete("modal-display-none") }} className="x">X</div></div>
                    <div className='modal-additive-confirm-delete'>
                        <div className='modal-additive-confirm-delete-text'>Deseja realmente apagar as informações a baixo ?</div>
                        <div className=''>


                            <table>
                                <thead className="box-itens-thead">
                                    <tr>
                                        <th>Código</th>
                                        <th>Descrição</th>
                                        <th>Cor</th>
                                        <th>Ref.</th>
                                        <th>Sexo</th>
                                        <th>Custo</th>
                                        <th>Medida</th>
                                        <th>Uni.</th>
                                        <th>M. de Obra</th>
                                        <th>Á vista</th>
                                        <th>Parcelado</th>
                                        <th>% de venda</th>
                                        <th colSpan="2">Valor</th>
                                        <th></th>
                                        <th></th>

                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th>Á vista</th>
                                        <th>Parcelado</th>
                                        
                                    </tr>
                                </thead>
                                <tbody className="box-itens-tbody">
                                    {categoryItemDelete &&
                                        (
                                            <tr>
                                                <td > <div className="box-itens-tbody-td">{categoryItemDelete.itemCode}</div>  </td>
                                                <td > <div className="box-itens-tbody-td">{categoryItemDelete.description}</div>  </td>
                                                <td > <div className="box-itens-tbody-td">{categoryItemDelete.color}</div>  </td>
                                                <td > <div className="box-itens-tbody-td">{categoryItemDelete.ref}</div>  </td>

                                                <td > <div className="box-itens-tbody-td">{categoryItemDelete.sex.value}</div>  </td>

                                                <td > <div className="box-itens-tbody-td">{categoryItemDelete.cost}</div>  </td>
                                                <td > <div className="box-itens-tbody-td">{categoryItemDelete.measure}</div>  </td>
                                                <td > <div className="box-itens-tbody-td">{categoryItemDelete.unit.value}</div>  </td>
                                                <td > <div className="box-itens-tbody-td">{categoryItemDelete.labor}</div>  </td>
                                                <td > <div className="box-itens-tbody-td">{categoryItemDelete.inCash}</div>  </td>
                                                <td > <div className="box-itens-tbody-td">{categoryItemDelete.instalment}</div>  </td>
                                                <td > <div className="box-itens-tbody-td">{categoryItemDelete.percentOfSale}</div>  </td>
                                                <td > <div className="box-itens-tbody-td">R$ {categoryItemDelete.inCashPrice}</div>  </td>
                                                <td > <div className="box-itens-tbody-td">R$ {categoryItemDelete.instalmentPrice}</div>  </td>
                                               
                                            </tr>

                                        )

                                    }

                                </tbody>
                            </table>


                        </div>
                        <div className='bt-model-add bt-model-1 bt-width-m2' onClick={() => { deleteCategoryItem() }} > Apagar</div>
                    </div>

                </div>

            </div >


        </section>

    )
}

export default FormCategoryItem