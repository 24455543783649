import './radio.css'

function Radio({options, name, value , defaultValue , handleOnChange}) {
    

    return (
        <div className="radio-div">
            {options.map((item) => (
                <div className="radio-option">
                    <div className="radio-check-box" onClick={() => handleOnChange(name,item.value)}>
                        {item.value ? ( item.value === value 
                        ?  <div className='radio-check-box-check radio-checked'>
                        </div>                      
                        :<div className='radio-check-box-check radio-uncheked'></div>
                        ):(
                            item.defaultValue === value ? 
                            <div className='radio-check-box-check radio-checked'>
                        </div> 
                        :<div className='radio-check-box-check radio-uncheked'></div>
                        )}
                        
                    </div>
                    <div className='radio-option-label'>
                    {item.label}
                    </div>
                </div>
            )

            )}
        </div>
    )
}

export default Radio