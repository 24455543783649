import api from '../../../utils/api'
import { useState, useEffect } from "react"
import { Link } from "react-router-dom"

/* form */
import Input from "../../../shared/components/form/input"

/* Hooks */
import useFlashMessage from '../../../hooks/useFlashMessage'

/* functions */
import TimestampToString from '../../../shared/functions/TimestampToString'

function ListBudget() {

    const budgetDefault = {
        code: "----",
        client: {
            name: "client",
            value: "",
            label: "",
        },
        installmentNumber:

            { name: "installmentNumber", value: "1", label: "1x", },
        paymentType:

            { name: "paymentType", value: "inCash", label: "Á vista", },


    }

    const [budget, setBudget] = useState([])
    const [token] = useState(localStorage.getItem('token') || '')
    const { setFlashMessage } = useFlashMessage()
    const { timeStampToDDMMAAAA } = TimestampToString()

    /* Search */
    const [search, setSearch] = useState('')
    const searchLowerCase = search.toLowerCase()


    const budgetSearch = budget.filter((budget) => budget.client.label.toLowerCase().includes(searchLowerCase))
    function handleChange(e) {
        setSearch(e.target.value)
    }
    useEffect(() => {
        api.get('/budget/getall', {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`
            }
        })
            .then((response) => {
                setBudget(response.data.budget)

            })
    }, [token])



    return (
        <section className='default-container-m1'>
            <h1 className='default-title-m1'> Orçamentos</h1>
            <div className='defaut-search'>
                <Link className="link-default bt-model-3 bt-width-m2 " to="/budget/new"> Novo Orçamento</Link>
                <form >
                    <div className='bt-width-m1'>
                        <Input


                            type="text"
                            name="search"
                            placeholder="Buscar"
                            handleOnChange={handleChange}

                        />
                    </div>
                </form>
            </div>

            <div className='List'>
                {/* Search */}
                {search.length > 0 ?
                    budgetSearch.map((budget) => (

                        <div className='category-list' key={budget._id}>
                            <div className='bold category-list-title'>
                                <div className='category-list-title-code'>{budget.code}</div>
                                <div className='category-list-title-cloth'>{budget.client.label}</div>
                                <div className='category-list-title-provider'>{timeStampToDDMMAAAA(budget.updatedAt)}</div>
                                <div className='category-list-title-product'>{budget.paymentType.label}</div>
                                <div className='category-list-title-description'>{budget.budgetPrice}</div>
                                <div className='action budget-list-title-action '>
                                    <Link className='' to={`/budget/PDF/${budget._id}`}><button className='bt PDF' title='Gerar PDF'></button></Link>
                                    <Link className='' to={`/budget/edit/${budget._id}`}><button className='bt edit' title='Editar'></button></Link>
                                    <button className='bt delete' title='Apagar' onClick={() => { }}></button>

                                </div>
                            </div>



                        </div>


                    )) : budget.length > 0 ?
                        budget.map((budget) => (

                            <div className='category-list' key={budget._id}>
                                <div className='bold category-list-title'>
                                    <div className='category-list-title-code'>{budget.code}</div>
                                    <div className='category-list-title-cloth'>{budget.client.label}</div>
                                    <div className='category-list-title-provider'>{timeStampToDDMMAAAA(budget.updatedAt)}</div>
                                    <div className='category-list-title-product'>{budget.paymentType.label}</div>
                                    <div className='category-list-title-description'> R$ {budget.budgetPrice}</div>
                                    <div className='action budget-list-title-action '>
                                        <Link className='' to={`/budget/PDF/${budget._id}`}><button className='bt PDF' title='Gerar PDF'></button></Link>
                                        <Link className='' to={`/budget/edit/${budget._id}`}><button className='bt edit' title='Editar'></button></Link>
                                        <button className='bt delete' title='Apagar' onClick={() => { }}></button>

                                    </div>
                                </div>



                            </div>


                        )) : budget.length === 0 && <p> Não há orçamentos cadastrados</p>}


            </div>
        </section>
    )
}

export default ListBudget