import api from '../../../utils/api'

import { useState , useEffect} from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'


/* Hooks */
import useFlashMessage from '../../../hooks/useFlashMessage'
import FormCategory from '../components/FormCategory'

function NewCategory(){
    const [token] = useState(localStorage.getItem('token') || '')
    const {setFlashMessage} = useFlashMessage()
    const navigate = useNavigate()
    const {id} = useParams()
    const [ category, setCategory] = useState({})

    
    useEffect(() => {

        api.get(`/category/get/${id}`, {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`,
            },
            
        }).then((Response) => {
            console.log(Response.data.category)
            setCategory(Response.data.category)
        })
    }, [token, id])
   

    async function updateCategory(category) {
        let msgType = 'success'
        
        
        const data = await api.patch(`category/up/${category._id}`, category, {
            headers: {
            Authorization: `Bearer ${JSON.parse(token)}`,
        },
        }).then((response) => {
            
            return response.data
        }).catch((err) => {
            
            msgType= 'error'
            return err.response.data
        })
        setFlashMessage(data.message, msgType)
        if (msgType !== 'error'){
            
            navigate('/category')
            }

    }



    return(
        
        <section >
            <div className='default-container-m1'>

            {category.cloth && (
               
                <FormCategory title={'Editar Categoria'} handleSubmit={updateCategory} categoryData={category} btnText="Salvar Alterações" />
           )}
        
        </div>
        </section>
    )
}

export default NewCategory