import api from '../../utils/api'

import { useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'



/* Hooks */
import useFlashMessage from '../../hooks/useFlashMessage'
import FormClient from './components/FormClient'

function NewClient(){
    const [token] = useState(localStorage.getItem('token') || '')
    const {setFlashMessage} = useFlashMessage()
    const navigate = useNavigate()
   
    async function registerClient(client) {
        let msgType = 'success'
        

        
            const data = await api.post('/client/new', client, {
                Authorization: `Bearer ${JSON.parse(token)}`
            }).then((response) => {
                return response.data
            })
            .catch((err) => {
                msgType = 'error'
                return err.response.data
            })
                
            
          
          
            
        
        setFlashMessage(data.message, msgType)
        console.log(client)
        if (msgType !== 'error'){
            
        navigate('/client')
        }
    }

    return(
        
        <section >
            <div className='default-container-m1'>
        <FormClient title={'Novo Cliente'} handleSubmit={registerClient} btnText="Cadastrar" />
        </div>
        </section>
    )
}

export default NewClient