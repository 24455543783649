import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import Select from 'react-select'

import api from '../../../utils/api'
/* CSS */
import "./formBudget.css"

/* Hooks */
import useFlashMessage from '../../../hooks/useFlashMessage'

/* form */
import Input from "../../../shared/components/form/input"

//functions
import RandomString from '../../../shared/functions/RandomString'

import '../pages/budget.css'
function FormBudget({ title, handleSubmit, budgetData }) {

    const budgetDefault = {
        code: "----",
        installmentNumber:

        { name: "installmentNumber", value: "1", label: "1x", },
    paymentType:

        { name: "paymentType", value: "inCash", label: "Á vista", },


    }
    const selectStyles = {
        control: (styles) => ({ ...styles, borderColor: "black", borderRadius: "10px", border: "1px solid #9e9e9e", height: "41px" }),
    }

    const BudgetDefault = {
       

       

        selectCommission: {
            commission: "0",
            discount: "0",
            label: "Des: 0% Com: 0%",
            name: "discount",
            value: "0",
            _id: "0",
        },
        selectedCategory: {
            additionalSize: {
                mPrice: "0.00",
                pPrice: "0.00",
                xgPrice: "0.00",
                xxgPrice: "0.00",
                xxxgPrice: "0.00",
            },

            additive: [
                {
                    additiveName: "",
                    price: "0.00",
                    _id: "",

                },],



            cloth: {
                label: "",
                name: "",
                value: "",
            },
            code: "",
            commission: [
                { commissionValue: '0', discountValue: '0', _id: '' }
            ],

            description: "",
            item: [
                {
                    color: "",
                    cost: "0.00",
                    description: "",
                    inCash: "0",
                    inCashPrice: "0",
                    instalment: "0",
                    instalmentPrice: "0",
                    itemCode: "0",
                    labor: "0.00",
                    measure: "0",
                    percentOfSale: "0",
                    ref: "",
                    sex: { name: 'sex', value: 'M', label: 'Masculino' },
                    unit: { name: 'unit', value: 'kg', label: 'Quilo' },
                    _id: "",
                }


            ],

            product:
            {
                label: "",
                name: "",
                value: ""
            },

            provider: {
                label: "",
                name: "",
                value: "",
                _id: "",
            },
        },
        selectedCategoryItem:
        {
            color: "",
            cost: "0.00",
            description: "",
            inCash: "70",
            inCashPrice: "0.00",
            instalment: "80",
            instalmentPrice: "0.00",
            itemCode: "",
            labor: "0.00",
            measure: "0",
            percentOfSale: "100",
            ref: "",
            sex:
            {
                label: "Masculino",
                name: "sex",
                value: "M",
            },
            unit:
            {
                label: "Quilo",
                name: "unit",
                value: "kg",
                _id: "",
            },
            totalCommission: "0.00",
            totalInCash: "0.00",
            totalInCashWithDis: "0.00",
            totalInstalment: "0.00",
            totalInstalmentWithDis: "0.00",
            totalQuantity: 0,
            _id: "",
        },
        installmentNumber:

            { name: "installmentNumber", value: "1", label: "1x", },
        paymentType:

            { name: "paymentType", value: "inCash", label: "Á vista", },

    }
    const budgetItemDefault = {
        itemCode: "",
        description: "",
        color: "",
        ref: "",
        labor: "0.00",
        cost: "0.00",
        inCash: "70",
        instalment: "80",
        percentOfSale: "100",
        unit: { label: "Metro", name: "unit", value: "m" },
        measure: "1",
        sex: { name: "sex", value: "M", label: "Masculino" },

    }
    const selectedCategoryDefault = {
        code: "",
        cloth: {
            name: "",
            value: "",
            label: "",
        },
        provider: {
            name: "",
            value: "",
            label: "",
        },
        product: {
            name: "",
            value: "",
            label: "",
        },
        description: "",
        additive: [],
        additionalSize: {
            pPrice: "0.00",
            mPrice: "22.22",
            xgPrice: "3.33",
            xxgPrice: "4.44",
            xxxgPrice: "5.55",
        },
        commission: [],
        item: [],

    }
    const selectedCategoryItemDefault = {
        itemCode: "",
        description: "",
        color: "",
        ref: "",
        labor: "",
        cost: "",
        inCash: "",
        instalment: "",
        percentOfSale: "",
        unit: {
            name: "unit",
            value: "",
            label: "",
        },
        measure: "",
        unit: {
            name: "sex",
            value: "",
            label: "",
        },
    }
    const quantitySizeDefault = {
        pQuantity: "0",
        mQuantity: "0",
        xgQuantity: "0",
        xxgQuantity: "0",
        xxxgQuantity: "0",

    }
    const addicionalSizeDefault = {
        pPrice: "0.00",
        mPrice: "0.00",
        xgPrice: "0.00",
        xxgPrice: "0.00",
        xxxgPrice: "0.00",

    }
    let budgetItemArrayData = []
    if(budgetData){
        budgetItemArrayData = budgetData.budgetItemArray
    }

    const [budgetItemArray, setBudgetItemArray] = useState(budgetItemArrayData || [])
    const [token] = useState(localStorage.getItem('token') || '')
    const { setFlashMessage } = useFlashMessage()
    const [userName] = useState(localStorage.getItem('userName') || '')
    const [budget, setBudget] = useState(budgetData || budgetDefault)
    const [client, setClient] = useState()
    const [categoryArray, setCategoryArray] = useState([])
    const [additiveArray, setAdditiveArray] = useState([])
    const [optionsClient, setOptionsClient] = useState([])
    const [optionsDiscount, setOptionsDiscount] = useState([])
    const [selectedItem, setSelectedItem] = useState({})
    const [selectedCategory, setSelectedCategory] = useState( selectedCategoryDefault)
    const [selectedCategoryItem, setSelectedCategoryItem] = useState(selectedCategoryItemDefault)
    const [quantitySize, setQuantitySize] = useState(quantitySizeDefault)
    const [additionalSizePrice, setAddicionalSizePrice] = useState(addicionalSizeDefault)
    const [selectCommission, setSelectCommission] = useState([])
    const [modalBudgetItemDelete, setModalBudgetItemDelete] = useState("modal-display-none")
    const [budgetItemDelete, setBudgetItemDelete] = useState()
    const [modalBudgetItemEdit, setModalBudgetItemEdit] = useState("modal-display-none")
    const [budgetItemEdit, setBudgetItemEdit] = useState()
    const [total, setTotal] = useState("0.00")
    /* Modal */
    const [modalBudgetItem, setModalBudgetItem] = useState("modal-display-none")
    /* Search */
    const [search, setSearch] = useState('')
    const searchLowerCase = search.toLowerCase()
    const categorySearch = categoryArray.filter((category) => category.description.toLowerCase().includes(searchLowerCase))
    console.log("budget arrayyyyyyyyyyyyyyy")
    console.log(budgetItemArray)
    console.log("additive selected")
    console.log(additiveArray)
    console.log("quantidade size")
    console.log(quantitySize)
    console.log("adicional size")
    

    function handleChangeSearch(e) {
        setSearch(e.target.value)


    }

    useEffect(() => {
        api.get('/client/getall', {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`
            }
        })
            .then((response) => {

                arrayToOptionsSelectClient(response.data.clients)

                /* setClient(response.data.clients) */
            })
    }, [token])
    useEffect(() => {
        api.get('/category/getall', {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`
            }
        })
            .then((response) => {

                setCategoryArray(response.data.category)


                /* setClient(response.data.clients) */
            })
    }, [token])


    function arrayToOptionsSelectClient(data) {

        data = data.map((i) => (
            { ...optionsClient, name: "client", value: i.clientName, label: i.clientName , _id: i._id}
        ))
        setOptionsClient(data)
    }
    function arrayToOptionsSelectDiscount(data) {
        console.log("dataaaaaaaaaaaaaaaaaaaaaaaaaaaaa")
        console.log(data)
        let array1 = [{ name: "discount", value: "nenhum", label: "Nenhum", _id: 0, commission: 0, discount: 0, }]
        let array2 = []
        data = data.map((i) => (
            { ...array2, name: "discount", value: i.discountValue, label: `Des: ${i.discountValue}% Com: ${i.commissionValue}%`, _id: i._id, commission: i.commissionValue, discount: i.discountValue }
        ))

        let newArray = data
        /* let newArray = array1.concat(data) */

        console.log(newArray)
        setOptionsDiscount(newArray)
    }
    function submit(e) {
    }
    function selectItem(category, item) {

        let cat = category.additive.map((i) => {
            return { ...i, checked: false }

        })
        setAdditiveArray(cat)
        setSelectedItem({ category_id: category._id, item_id: item._id })
        setSelectedCategory(category)
        setSelectedCategoryItem(item)
        setAddicionalSizePrice(category.additionalSize)
        arrayToOptionsSelectDiscount(category.commission)
    }
    const checkIfSelected = (category_id, item_id) => {

        if (category_id === selectedItem.category_id) {
            if (item_id === selectedItem.item_id) {
                return 'modal-budget-item-line modal-budget-item-line-select '
            }
        }


        return 'modal-budget-item-line'
    }
    function handleChangeSize(e) {
        let x = parseInt(e.target.value)
        x = x + ""
        console.log("vazio")
        console.log(e.target.value)
        if (e.target.value === "") (
            x = "0"
        )
        setQuantitySize({ ...quantitySize, [e.target.name]: x })


    }
    const handleSelectChange = (selectedOption) => {
        console.log(selectedOption)
        setBudget({ ...budget, [selectedOption.name]: selectedOption })
    }
    const handleSelectChangeCommission = (selectedOption) => {
        console.log(selectedOption)
        setSelectCommission(selectedOption)
    }
    function checkAdditive(id, option) {
        console.log("ahhhhhhhhhhhhhhhhhhhhhhhh")
        setAdditiveArray((prev) => {
            return prev.map((i) => {
                if (i._id === id) {
                    return { ...i, checked: option }
                } else {
                    return { ...i }
                }
            })
        })

    }

    function totalCalcQuantity() {
        let quant = 0
        quant = parseInt(quantitySize.pQuantity) +
            parseInt(quantitySize.mQuantity) +
            parseInt(quantitySize.xgQuantity) +
            parseInt(quantitySize.xxgQuantity) +
            parseInt(quantitySize.xxxgQuantity)
        return quant
    }
    function totalCalcSize() {

        let price = 0.0
        console.log(additionalSizePrice)
        console.log("Aquiiiiiiiiiiiiiiiiiiiiiiiii")
        price = parseInt(quantitySize.pQuantity) * parseFloat(additionalSizePrice.pPrice) +
            parseInt(quantitySize.mQuantity) * parseFloat(additionalSizePrice.mPrice) +
            parseInt(quantitySize.xgQuantity) * parseFloat(additionalSizePrice.xgPrice) +
            parseInt(quantitySize.xxgQuantity) * parseFloat(additionalSizePrice.xxgPrice) +
            parseInt(quantitySize.xxxgQuantity) * parseFloat(additionalSizePrice.xxxgPrice)




        console.log(price)
        return price
    }
    function calcSizePrice(price, perCent, sizePrice, quant) {
        let additiveTotal = 0.0
        additiveArray.map((i) => {
            if (i.checked === true) {
                additiveTotal = additiveTotal + parseFloat(i.price)

            }


        })
        let val = (parseFloat(price)) + (((parseFloat(additiveTotal) + parseFloat(sizePrice)) * (1 + parseFloat(perCent) / 100)) * (1 + parseFloat(selectedCategoryItem.percentOfSale) / 100))
        val = val * quant
        val = val.toFixed(2)

        return val
    }
    function totalCalcInCash() {

        let additiveTotal = 0.0
        additiveArray.map((i) => {
            if (i.checked === true) {
                additiveTotal = additiveTotal + parseFloat(i.price)

            }


        })

        let valP = (parseFloat(selectedCategoryItem.inCashPrice)) + (((parseFloat(additiveTotal) + parseFloat(additionalSizePrice.pPrice)) * (1 + parseFloat(selectedCategoryItem.inCash) / 100)) * (1 + parseFloat(selectedCategoryItem.percentOfSale) / 100))
        valP = valP * quantitySize.pQuantity
        let valM = (parseFloat(selectedCategoryItem.inCashPrice)) + (((parseFloat(additiveTotal) + parseFloat(additionalSizePrice.mPrice)) * (1 + parseFloat(selectedCategoryItem.inCash) / 100)) * (1 + parseFloat(selectedCategoryItem.percentOfSale) / 100))
        valM = valM * quantitySize.mQuantity
        let valXG = (parseFloat(selectedCategoryItem.inCashPrice)) + (((parseFloat(additiveTotal) + parseFloat(additionalSizePrice.xgPrice)) * (1 + parseFloat(selectedCategoryItem.inCash) / 100)) * (1 + parseFloat(selectedCategoryItem.percentOfSale) / 100))
        valXG = valXG * quantitySize.xgQuantity
        let valXXG = (parseFloat(selectedCategoryItem.inCashPrice)) + (((parseFloat(additiveTotal) + parseFloat(additionalSizePrice.xxgPrice)) * (1 + parseFloat(selectedCategoryItem.inCash) / 100)) * (1 + parseFloat(selectedCategoryItem.percentOfSale) / 100))
        valXXG = valXXG * quantitySize.xxgQuantity
        let valXXXG = (parseFloat(selectedCategoryItem.inCashPrice)) + (((parseFloat(additiveTotal) + parseFloat(additionalSizePrice.xxxgPrice)) * (1 + parseFloat(selectedCategoryItem.inCash) / 100)) * (1 + parseFloat(selectedCategoryItem.percentOfSale) / 100))
        valXXXG = valXXXG * quantitySize.xxxgQuantity



        let val = valP + valM + valXG + valXXG + valXXXG
        val = val.toFixed(2)


        return val

    }
    function totalCalcInstalment() {

        let additiveTotal = 0.0
        additiveArray.map((i) => {
            if (i.checked === true) {
                additiveTotal = additiveTotal + parseFloat(i.price)

            }


        })

        let valP = (parseFloat(selectedCategoryItem.inCashPrice)) + (((parseFloat(additiveTotal) + parseFloat(additionalSizePrice.pPrice)) * (1 + parseFloat(selectedCategoryItem.instalment) / 100)) * (1 + parseFloat(selectedCategoryItem.percentOfSale) / 100))
        valP = valP * quantitySize.pQuantity
        let valM = (parseFloat(selectedCategoryItem.inCashPrice)) + (((parseFloat(additiveTotal) + parseFloat(additionalSizePrice.mPrice)) * (1 + parseFloat(selectedCategoryItem.instalment) / 100)) * (1 + parseFloat(selectedCategoryItem.percentOfSale) / 100))
        valM = valM * quantitySize.mQuantity
        let valXG = (parseFloat(selectedCategoryItem.inCashPrice)) + (((parseFloat(additiveTotal) + parseFloat(additionalSizePrice.xgPrice)) * (1 + parseFloat(selectedCategoryItem.instalment) / 100)) * (1 + parseFloat(selectedCategoryItem.percentOfSale) / 100))
        valXG = valXG * quantitySize.xgQuantity
        let valXXG = (parseFloat(selectedCategoryItem.inCashPrice)) + (((parseFloat(additiveTotal) + parseFloat(additionalSizePrice.xxgPrice)) * (1 + parseFloat(selectedCategoryItem.instalment) / 100)) * (1 + parseFloat(selectedCategoryItem.percentOfSale) / 100))
        valXXG = valXXG * quantitySize.xxgQuantity
        let valXXXG = (parseFloat(selectedCategoryItem.inCashPrice)) + (((parseFloat(additiveTotal) + parseFloat(additionalSizePrice.xxxgPrice)) * (1 + parseFloat(selectedCategoryItem.instalment) / 100)) * (1 + parseFloat(selectedCategoryItem.percentOfSale) / 100))
        valXXXG = valXXXG * quantitySize.xxxgQuantity



        let val = valP + valM + valXG + valXXG + valXXXG
        val = val.toFixed(2)


        return val

    }
    function totalCalcDiscount(valor) {
        if (selectCommission.discount) {
            let dis = selectCommission
            console.log("disssssssssssssssssssssssssssssssssssssssssss")
            console.log(dis)
            let valorDis = parseFloat(valor) - (parseFloat(valor) * (parseFloat(dis.discount) / 100))

            valorDis = valorDis.toFixed(2)
            console.log(valor)
            return valorDis
        }
        return 0

    }
    function totalCalcCommission(valor) {
        if (selectCommission.commission) {
            let com = selectCommission
            console.log("commmmmmmmmmmmmmmmmmmmmmmmmmmmmmm")
            console.log(com)
            let valorCom = (parseFloat(valor) * (parseFloat(com.commission) / 100))

            valorCom = valorCom.toFixed(2)
            console.log(valor)
            return valorCom
        }
        return 0

    }
    function calcPriceBudget(paymentType) {

        let x = 0

        if (budgetItemArray) {

            if (paymentType.value === "inCash") {
                budgetItemArray.map((i) => {
                    x = x + parseFloat(i.totalInCashWithDis)
                })
            }
            if (paymentType.value === "instalment") {
                budgetItemArray.map((i) => {
                    x = x + parseFloat(i.totalInstalmentWithDis)
                })
            }
        }
        x = x.toFixed(2)

        return x
    }
    function calcTotalComission() {

        let x = 0

        if (budgetItemArray) {

            budgetItemArray.map((i) => {
                x = x + parseFloat(i.totalCommission)
            })

        }
        x = x.toFixed(2)

        return x
    }
    function CalcInstalmentNumber() {

        let x = 0
        let price = calcPriceBudget(budget.paymentType)

        x = parseFloat(price) / parseFloat(budget.installmentNumber.value)

        x = x.toFixed(2)

        return x
    }
    function addItemToBudget() {
        let id = RandomString(16)

        let array1 = []
        array1 = [{
            _id: id,
            selectedCategory: selectedCategory,
            selectedCategoryItem: selectedCategoryItem,
            additiveArray: additiveArray,
            quantitySize: quantitySize,
            selectCommission: selectCommission,
            totalQuantity: totalCalcQuantity(),
            totalInCash: totalCalcInCash(),
            totalInstalment: totalCalcInstalment(),
            totalInCashWithDis: totalCalcDiscount(totalCalcInCash()),
            totalInstalmentWithDis: totalCalcDiscount(totalCalcInstalment()),
            totalCommission: totalCalcCommission(totalCalcInstalment()),


        }]


        console.log("ARRAY FINALLLLLL")
        console.log(array1)
        let array = budgetItemArray.concat(array1)
        setBudgetItemArray(array)
        setModalBudgetItem("modal-display-none")
    }

    function editItemToBudget(id) {


        let array1 = []
        array1 = [{
            _id: id,
            selectedCategory: selectedCategory,
            selectedCategoryItem: selectedCategoryItem,
            additiveArray: additiveArray,
            quantitySize: quantitySize,
            selectCommission: selectCommission,
            totalQuantity: totalCalcQuantity(),
            totalInCash: totalCalcInCash(),
            totalInstalment: totalCalcInstalment(),
            totalInCashWithDis: totalCalcDiscount(totalCalcInCash()),
            totalInstalmentWithDis: totalCalcDiscount(totalCalcInstalment()),
            totalCommission: totalCalcCommission(totalCalcInstalment()),


        }]


        console.log("ARRAY FINALLLLLL")
        console.log(array1)
        let array = budgetItemArray.concat(array1)
        setBudgetItemArray(array)

    }
    function removeBudgetItem(data) {
        setModalBudgetItemDelete("modal-display-flex")
        setBudgetItemDelete(data)

    }
    function deleteBudgetItem() {
        const updatedArray = budgetItemArray.filter((i) => {
            return i._id !== budgetItemDelete._id
        })
        setBudgetItemArray(updatedArray)
        setModalBudgetItemDelete("modal-display-none")
    }
    function editBudgetItem(data) {
        setBudgetItemEdit(data)
        setModalBudgetItemEdit("modal-display-flex")
        let selectedCategory = data.selectedCategory
        let selectedItem = data.selectedCategoryItem
        setSelectedItem({ category_id: selectedCategory._id, item_id: selectedItem._id })
        setSelectedCategory(selectedCategory)
        setSelectedCategoryItem(data.selectedCategoryItem)
        setAdditiveArray(data.additiveArray)
        setQuantitySize(data.quantitySize)
        arrayToOptionsSelectDiscount(selectedCategory.commission)
        setSelectCommission(data.selectCommission)

    }
    function editItemToBudget() {
        let data = budgetItemArray.map((item) => {
            if (budgetItemEdit._id === item._id) {



                return {
                    _id: budgetItemEdit._id,
                    selectedCategory: selectedCategory,
                    selectedCategoryItem: selectedCategoryItem,
                    additiveArray: additiveArray,
                    quantitySize: quantitySize,
                    selectCommission: selectCommission,
                    totalQuantity: totalCalcQuantity(),
                    totalInCash: totalCalcInCash(),
                    totalInstalment: totalCalcInstalment(),
                    totalInCashWithDis: totalCalcDiscount(totalCalcInCash()),
                    totalInstalmentWithDis: totalCalcDiscount(totalCalcInstalment()),
                    totalCommission: totalCalcCommission(totalCalcInstalment()),


                }
            } else {
                return item
            }
        }
        )
        setBudgetItemArray(data)
        setModalBudgetItemEdit("modal-display-none")
    }

    const optionsPayment = [
        { name: "paymentType", value: "inCash", label: "Á vista", },
        { name: "paymentType", value: "instalment", label: "Parcelado", },
    ]
    const optionsInstallmentNumber = [
        { name: "installmentNumber", value: "1", label: "1x", },
        { name: "installmentNumber", value: "2", label: "2x", },
        { name: "installmentNumber", value: "3", label: "3x", },
        { name: "installmentNumber", value: "4", label: "4x", },
        { name: "installmentNumber", value: "5", label: "5x", },
        { name: "installmentNumber", value: "6", label: "6x", },
        { name: "installmentNumber", value: "7", label: "7x", },
        { name: "installmentNumber", value: "8", label: "8x", },
        { name: "installmentNumber", value: "9", label: "9x", },
        { name: "installmentNumber", value: "10", label: "10x", },

    ]
    async function saveBudget() {
        let obj

       obj = {
            ...budget,
            budgetItemArray: budgetItemArray,
            installmentValue: CalcInstalmentNumber(),
            budgetPrice: calcPriceBudget(budget.paymentType),
            budgetCommission: calcTotalComission()
        }

        console.log(obj)
       handleSubmit(obj)


    }


    return (
        <div className="container-form-budget">
            <div className="center-form-budget">
                <h1 className='default-title-m1'>{title}</h1>
                <form onSubmit={submit} className="form-box-budget ">
                    <div className="form-category-col-1">

                        <div className='input-m-3 form-group'>
                            <label>Código</label>
                            <div className='input-block'> {budget.code} </div>
                        </div>
                        <div className='input-m-2 form-group'>
                            <label>Vendedor</label>
                            <div className='input-block'> {userName} </div>
                        </div>

                    </div>
                    <div className="form-category-col-1">
                        <div className="input-m-1 form-group">
                            <label className="input-label">Cliente:

                            </label>
                            <Select
                                options={optionsClient}
                                styles={selectStyles}
                                onChange={handleSelectChange}
                                value={budget.client || ""}

                            />
                        </div>


                    </div>

                </form>
                <section className="box-itens">

                    <table>
                        <thead className="box-itens-thead">
                            <tr>
                                <th>Código</th>
                                <th>Quant.</th>
                                <th>Descrição</th>
                                <th>Detalhes</th>

                                <th>Des% Com%</th>
                                <th>Valor Total</th>
                                <th>Valor Total</th>
                                <th>Comissão</th>



                            </tr>
                            <tr>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th>á vista</th>
                                <th>parcelado</th>
                                <th></th>

                            </tr>
                        </thead>
                        <tbody className="box-itens-tbody">
                            {budgetItemArray &&
                                budgetItemArray.map((x) =>
                                    <tr>
                                        <td > <div className="box-itens-tbody-td">{x.code}</div>  </td>
                                        <td > <div className="box-itens-tbody-td">{x.totalQuantity}</div>  </td>
                                        <td > <div className="box-itens-tbody-td">{x.selectedCategory.description}</div>  </td>
                                        <td > <div className="box-itens-tbody-td">{x.selectedCategoryItem.description} , <span className="budget-item-color ">{x.selectedCategoryItem.color}</span>,
                                            {x.selectedCategoryItem.sex.label === "Masculino" ?
                                                <span className="budget-item-sex-m ">{x.selectedCategoryItem.sex.label}</span> :
                                                <span className="budget-item-sex-f ">{x.selectedCategoryItem.sex.label}</span>
                                            }
                                            {x.additiveArray &&

                                                x.additiveArray.map((addi) =>
                                                    <span>
                                                        {addi.checked === true ? <span className="budget-item-additive-with">{`com ${addi.additiveName}`} </span> : <span className="budget-item-additive-without">{`sem ${addi.additiveName}`} </span>}
                                                    </span>

                                                )
                                            }



                                        </div>  </td>

                                        <td > <div className="box-itens-tbody-td">{`${x.selectCommission.discount}% ${x.selectCommission.commission}% `}</div>  </td>

                                        <td > <div className="box-itens-tbody-td">R${x.totalInCashWithDis}</div>  </td>
                                        <td > <div className="box-itens-tbody-td">R${x.totalInstalmentWithDis}</div>  </td>
                                        <td > <div className="box-itens-tbody-td">R${x.totalCommission}</div>  </td>

                                        <td ><div className=' box-itens-tbody-td-action'>
                                            <div className="bt edit" title="editar" onClick={() => { editBudgetItem(x) }}></div>
                                            <div className='bt delete' title='Apagar' onClick={() => { removeBudgetItem(x) }}></div>

                                        </div></td>
                                    </tr>

                                )

                            }

                        </tbody>
                    </table>
                </section>
                <div className="box-itens-bt">
                    <div className="box-itens-bt-add bt-model-add " onClick={() => { setModalBudgetItem("modal-display-flex") }}> Adicionar Item</div>
                </div>
                <div className="form-category-col-1">
                    <div className="form-category-col-1">
                        <div className="input-m-2 form-group">
                            <label className="input-label">Forma de pagamento

                            </label>
                            <Select
                                options={optionsPayment}
                                styles={selectStyles}
                                onChange={handleSelectChange}
                                value={budget.paymentType || ""}

                            />
                        </div>



                        {budget.paymentType.value === "instalment" &&
                            <div className="input-m-3 form-group">
                                <label className="input-label">N° parcelas

                                </label>
                                <Select
                                    options={optionsInstallmentNumber}
                                    styles={selectStyles}
                                    onChange={handleSelectChange}
                                    value={budget.installmentNumber || ""}

                                />
                            </div>}
                        {budget.paymentType.value === "instalment" &&
                            <div className='input-m-4 form-group'>
                                <label>Parcelas</label>
                                <div className='input-block'> R$ {CalcInstalmentNumber()} </div>
                            </div>}





                        <div className='input-m-4 form-group'>
                            <label>Valor do Orçamento</label>
                            <div className='input-block'> R$  {calcPriceBudget(budget.paymentType)}</div>
                        </div>
                        <div className='input-m-4 form-group'>
                            <label>Valor da Comissão</label>
                            <div className='input-block'> R$  {calcTotalComission()}</div>
                        </div>


                    </div>

                </div>
                <div className="form-group-m1 form-group-left ">


                    <div className="bt-model-add bt-width-m2 bt-model-2" onClick={() => { saveBudget() }}> Salvar </div>

                    <div className="bt-model-cancel  bt-width-m2 bt-model-2" onClick={() => { setModalBudgetItemEdit("modal-display-none") }}>Cancelar</div>
                </div>
            </div>


            {/* FORM NEW ITEM */}

            <div className={`category-modal-background ${modalBudgetItem} `} >
                <div className="budget-modal-item ">
                    <div className="box-x"> <div onClick={() => { setModalBudgetItem("modal-display-none") }} className="x">X</div></div>
                    <div className="budget-modal-item-container">
                        <h1 className='default-title-m1'> Adicionar Item ao Orçamento</h1>
                        <div className='defaut-search'>
                            <form >
                                <div className='bt-width-m1'>
                                    <Input


                                        type="text"
                                        name="search"
                                        placeholder="Buscar"
                                        handleOnChange={handleChangeSearch}

                                    />
                                </div>
                            </form>
                        </div>

                        <div>
                            <div>Item</div>
                            <div className="modal-budget-item-box">
                                {categoryArray &&
                                    categoryArray.map((i) =>
                                        <>

                                            {i.item.map((x) =>
                                                <div className={checkIfSelected(i._id, x._id)} onClick={() => { selectItem(i, x) }}>
                                                    <div> {x.description} </div>
                                                    <div>{i.provider.label}</div>
                                                    <div>{x.color}</div>
                                                    <div>{i.cloth.label}</div>
                                                    <div>{x.sex.label}</div>
                                                    <div>{i.additive.map((a) => (
                                                        <div> {a.additiveName}</div>
                                                    ))}</div>
                                                </div>
                                            )}
                                        </>
                                    )
                                }
                            </div>
                        </div>
                        <div>
                            <div>Selecionado</div>
                            <div className="budget-subitem">
                                <div className="form-category-col-1">

                                    <div className='input-m-3 form-group'>
                                        <label>Código</label>
                                        <div className='input-block'> {selectedCategory.code} </div>
                                    </div>
                                    <div className='input-m-2 form-group'>
                                        <label>Tecido</label>
                                        <div className='input-block'> {selectedCategory.cloth.label || ""} </div>
                                    </div>
                                    <div className='input-m-2 form-group'>
                                        <label>Produto</label>
                                        <div className='input-block'> {selectedCategory.product.label || ""} </div>
                                    </div>
                                    <div className='input-m-1 form-group'>
                                        <label>Descrição</label>
                                        <div className='input-block'> {selectedCategory.description || ""} </div>
                                    </div>

                                </div>
                            </div>
                            <div>Cor</div>
                            <div className="budget-subitem">
                                <div className="form-category-col-1">

                                    <div className='input-m-2 form-group'>
                                        <label>Fabricante</label>
                                        <div className='input-block'> {selectedCategory.provider.label} </div>
                                    </div>
                                    <div className='input-m-2 form-group'>
                                        <label>Código de Ref.</label>
                                        <div className='input-block'> {selectedCategoryItem.ref || ""} </div>
                                    </div>
                                    <div className='input-m-2 form-group'>
                                        <label>Cor</label>
                                        <div className='input-block'> {selectedCategoryItem.color || ""} </div>
                                    </div>


                                </div>
                            </div>
                            <div>Aditivo</div>
                            <div className="budget-subitem">
                                <div className="form-budget-additive">

                                    {additiveArray.map((i) =>
                                        <div className="form-budget-additive-option">
                                            <div className="radio-option radio-option-additive">
                                                <div className="radio-budget-check-box" onClick={() => { checkAdditive(i._id, false) }}>
                                                    {i.checked === false
                                                        ? <div className='radio-budget-check-box-check radio-checked'>
                                                        </div>
                                                        : <div className='radio-budget-check-box-check radio-uncheked'></div>
                                                    }

                                                </div>
                                                <div className='radio-option-label'>
                                                    Sem {i.additiveName}
                                                </div>
                                            </div>
                                            <div className="radio-option">
                                                <div className="radio-budget-check-box" onClick={() => { checkAdditive(i._id, true) }}>
                                                    {i.checked === true
                                                        ? <div className='radio-budget-check-box-check radio-checked'>
                                                        </div>
                                                        : <div className='radio-budget-check-box-check radio-uncheked'></div>
                                                    }

                                                </div>
                                                <div className='radio-option-label'>
                                                    Com {i.additiveName}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    }


                                </div>
                            </div>

                            <div>Acessorios</div>
                            <div className="budget-subitem">
                                ! em construção !
                            </div>
                            <div>Escolha os tamanhos</div>
                            <div className="budget-subitem">
                                <div className="budget-size-box">
                                    <div className="budget-size-box-item">Tamanhos</div>
                                    <div className="budget-size-box-item">Quantidade</div>
                                    <div className="budget-size-box-item">Valor</div>
                                </div>
                                <div className="budget-size-box">
                                    <div className="budget-size-box-item-name">P</div>
                                    <div className="budget-size-box-item">
                                        <div className="input-m-3 ">
                                            <Input

                                                type="number"
                                                name="pQuantity"
                                                handleOnChange={handleChangeSize}
                                                value={quantitySize.pQuantity || ""}

                                            />
                                        </div>
                                    </div>
                                    <div className="budget-size-box-item">
                                        <div className="input-m-3 ">
                                            <div className='input-block'> R$ {calcSizePrice(selectedCategoryItem.inCashPrice, selectedCategoryItem.inCash, additionalSizePrice.pPrice, quantitySize.pQuantity)} </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="budget-size-box">
                                    <div className="budget-size-box-item-name">M</div>
                                    <div className="budget-size-box-item">
                                        <div className="input-m-3 ">
                                            <Input

                                                type="number"
                                                name="mQuantity"
                                                handleOnChange={handleChangeSize}
                                                value={quantitySize.mQuantity || ""}

                                            />
                                        </div>
                                    </div>
                                    <div className="budget-size-box-item">
                                        <div className="input-m-3 ">
                                            <div className='input-block'> R$ {calcSizePrice(selectedCategoryItem.inCashPrice, selectedCategoryItem.inCash, additionalSizePrice.mPrice, quantitySize.mQuantity)} </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="budget-size-box">
                                    <div className="budget-size-box-item-name">XG</div>
                                    <div className="budget-size-box-item">
                                        <div className="input-m-3 ">
                                            <Input

                                                type="number"
                                                name="xgQuantity"
                                                handleOnChange={handleChangeSize}
                                                value={quantitySize.xgQuantity || ""}

                                            />
                                        </div>
                                    </div>
                                    <div className="budget-size-box-item">
                                        <div className="input-m-3 ">
                                            <div className='input-block'> R$ {calcSizePrice(selectedCategoryItem.inCashPrice, selectedCategoryItem.inCash, additionalSizePrice.xgPrice, quantitySize.xgQuantity)} </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="budget-size-box">
                                    <div className="budget-size-box-item-name">XXG</div>
                                    <div className="budget-size-box-item">
                                        <div className="input-m-3 ">
                                            <Input

                                                type="number"
                                                name="xxgQuantity"
                                                handleOnChange={handleChangeSize}
                                                value={quantitySize.xxgQuantity || ""}

                                            />
                                        </div>
                                    </div>
                                    <div className="budget-size-box-item">
                                        <div className="input-m-3 ">
                                            <div className='input-block'> R$ {calcSizePrice(selectedCategoryItem.inCashPrice, selectedCategoryItem.inCash, additionalSizePrice.xxgPrice, quantitySize.xxgQuantity)} </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="budget-size-box">
                                    <div className="budget-size-box-item-name">XXXG</div>
                                    <div className="budget-size-box-item">
                                        <div className="input-m-3 ">
                                            <Input

                                                type="number"
                                                name="xxxgQuantity"
                                                handleOnChange={handleChangeSize}
                                                value={quantitySize.xxxgQuantity || ""}

                                            />
                                        </div>
                                    </div>
                                    <div className="budget-size-box-item">
                                        <div className="input-m-3 ">
                                            <div className='input-block'> R$ {calcSizePrice(selectedCategoryItem.inCashPrice, selectedCategoryItem.inCash, additionalSizePrice.xxxgPrice, quantitySize.xxxgQuantity)} </div>
                                        </div>
                                    </div>
                                </div>

                            </div>



                            <div className="budget-subitem">
                                <div className="form-category-col-1">

                                    <div className='input-m-3 form-group'>
                                        <label>Quantidade</label>
                                        <div className='input-block'> {totalCalcQuantity()} </div>
                                    </div>

                                    <div className='input-m-2 form-group'>
                                        <label>Total à vista</label>
                                        <div className='input-block'> R$ {totalCalcInCash()} </div>
                                    </div>
                                    <div className='input-m-2 form-group'>
                                        <label>Total parcelado</label>
                                        <div className='input-block'> R$ {totalCalcInstalment()} </div>
                                    </div>



                                </div>
                            </div>
                            <div>Com desconto</div>
                            <div className="budget-subitem">
                                <div className='input-m-2 form-group'>
                                    <label className="input-label">Desconto e Comissão:</label>
                                    <Select
                                        options={optionsDiscount}
                                        styles={selectStyles}
                                        onChange={handleSelectChangeCommission}
                                        value={selectCommission}
                                    />


                                </div>
                            </div>
                            <div className="budget-subitem">
                                <div className="form-category-col-1">

                                    <div className='input-m-3 form-group'>
                                        <label>Quantidade</label>
                                        <div className='input-block'> {totalCalcQuantity()} </div>
                                    </div>

                                    <div className='input-m-2 form-group'>
                                        <label>Total à vista</label>
                                        <div className='input-block'> R$ {totalCalcDiscount(totalCalcInCash())} </div>
                                    </div>
                                    <div className='input-m-2 form-group'>
                                        <label>Total parcelado</label>
                                        <div className='input-block'> R$ {totalCalcDiscount(totalCalcInstalment())} </div>
                                    </div>
                                    <div className='input-m-2 form-group'>
                                        <label>Comissão</label>
                                        <div className='input-block'> R$ {totalCalcCommission(totalCalcInstalment())} </div>
                                    </div>



                                </div>
                            </div>
                        </div>
                        <div className="form-group-m1 form-group-left ">


                            <div className="bt-model-add bt-width-m2 bt-model-2" onClick={() => { addItemToBudget() }}> Adicionar </div>
                            <div className="bt-model-cancel  bt-width-m2 bt-model-2" onClick={() => { setModalBudgetItem("modal-display-none") }}>Cancelar</div>
                        </div>
                    </div>


                </div>
            </div >

            {/*FORM EDIT ITEM */}
            <div className={`category-modal-background ${modalBudgetItemEdit} `} >
                <div className="budget-modal-item ">
                    <div className="box-x"> <div onClick={() => { setModalBudgetItemEdit("modal-display-none") }} className="x">X</div></div>
                    <div className="budget-modal-item-container">
                        <h1 className='default-title-m1'> Adicionar Item ao Orçamento</h1>
                        <div className='defaut-search'>
                            <form >
                                <div className='bt-width-m1'>
                                    <Input


                                        type="text"
                                        name="search"
                                        placeholder="Buscar"
                                        handleOnChange={handleChangeSearch}

                                    />
                                </div>
                            </form>
                        </div>

                        <div>
                            <div>Item</div>
                            <div className="modal-budget-item-box">
                                {categoryArray &&
                                    categoryArray.map((i) =>
                                        <>

                                            {i.item.map((x) =>
                                                <div className={checkIfSelected(i._id, x._id)} onClick={() => { selectItem(i, x) }}>
                                                    <div> {x.description} </div>
                                                    <div>{i.provider.label}</div>
                                                    <div>{x.color}</div>
                                                    <div>{i.cloth.label}</div>
                                                    <div>{x.sex.label}</div>
                                                    <div>{i.additive.map((a) => (
                                                        <div> {a.additiveName}</div>
                                                    ))}</div>
                                                </div>
                                            )}
                                        </>
                                    )
                                }
                            </div>
                        </div>
                        <div>
                            <div>Selecionado</div>
                            <div className="budget-subitem">
                                <div className="form-category-col-1">

                                    <div className='input-m-3 form-group'>
                                        <label>Código</label>
                                        <div className='input-block'> {selectedCategory.code} </div>
                                    </div>
                                    <div className='input-m-2 form-group'>
                                        <label>Tecido</label>
                                        <div className='input-block'> {selectedCategory.cloth.label || ""} </div>
                                    </div>
                                    <div className='input-m-2 form-group'>
                                        <label>Produto</label>
                                        <div className='input-block'> {selectedCategory.product.label || ""} </div>
                                    </div>
                                    <div className='input-m-1 form-group'>
                                        <label>Descrição</label>
                                        <div className='input-block'> {selectedCategory.description || ""} </div>
                                    </div>

                                </div>
                            </div>
                            <div>Cor</div>
                            <div className="budget-subitem">
                                <div className="form-category-col-1">

                                    <div className='input-m-2 form-group'>
                                        <label>Fabricante</label>
                                        <div className='input-block'> {selectedCategory.provider.label} </div>
                                    </div>
                                    <div className='input-m-2 form-group'>
                                        <label>Código de Ref.</label>
                                        <div className='input-block'> {selectedCategoryItem.ref || ""} </div>
                                    </div>
                                    <div className='input-m-2 form-group'>
                                        <label>Cor</label>
                                        <div className='input-block'> {selectedCategoryItem.color || ""} </div>
                                    </div>


                                </div>
                            </div>
                            <div>Aditivo</div>
                            <div className="budget-subitem">
                                <div className="form-budget-additive">

                                    {additiveArray.map((i) =>
                                        <div className="form-budget-additive-option">
                                            <div className="radio-option radio-option-additive">
                                                <div className="radio-budget-check-box" onClick={() => { checkAdditive(i._id, false) }}>
                                                    {i.checked === false
                                                        ? <div className='radio-budget-check-box-check radio-checked'>
                                                        </div>
                                                        : <div className='radio-budget-check-box-check radio-uncheked'></div>
                                                    }

                                                </div>
                                                <div className='radio-option-label'>
                                                    Sem {i.additiveName}
                                                </div>
                                            </div>
                                            <div className="radio-option">
                                                <div className="radio-budget-check-box" onClick={() => { checkAdditive(i._id, true) }}>
                                                    {i.checked === true
                                                        ? <div className='radio-budget-check-box-check radio-checked'>
                                                        </div>
                                                        : <div className='radio-budget-check-box-check radio-uncheked'></div>
                                                    }

                                                </div>
                                                <div className='radio-option-label'>
                                                    Com {i.additiveName}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    }


                                </div>
                            </div>

                            <div>Acessorios</div>
                            <div className="budget-subitem">
                                ! em construção !
                            </div>
                            <div>Escolha os tamanhos</div>
                            <div className="budget-subitem">
                                <div className="budget-size-box">
                                    <div className="budget-size-box-item">Tamanhos</div>
                                    <div className="budget-size-box-item">Quantidade</div>
                                    <div className="budget-size-box-item">Valor</div>
                                </div>
                                <div className="budget-size-box">
                                    <div className="budget-size-box-item-name">P</div>
                                    <div className="budget-size-box-item">
                                        <div className="input-m-3 ">
                                            <Input

                                                type="number"
                                                name="pQuantity"
                                                handleOnChange={handleChangeSize}
                                                value={quantitySize.pQuantity || ""}

                                            />
                                        </div>
                                    </div>
                                    <div className="budget-size-box-item">
                                        <div className="input-m-3 ">
                                            <div className='input-block'> R$ {calcSizePrice(selectedCategoryItem.inCashPrice, selectedCategoryItem.inCash, additionalSizePrice.pPrice, quantitySize.pQuantity)} </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="budget-size-box">
                                    <div className="budget-size-box-item-name">M</div>
                                    <div className="budget-size-box-item">
                                        <div className="input-m-3 ">
                                            <Input

                                                type="number"
                                                name="mQuantity"
                                                handleOnChange={handleChangeSize}
                                                value={quantitySize.mQuantity || ""}

                                            />
                                        </div>
                                    </div>
                                    <div className="budget-size-box-item">
                                        <div className="input-m-3 ">
                                            <div className='input-block'> R$ {calcSizePrice(selectedCategoryItem.inCashPrice, selectedCategoryItem.inCash, additionalSizePrice.mPrice, quantitySize.mQuantity)} </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="budget-size-box">
                                    <div className="budget-size-box-item-name">XG</div>
                                    <div className="budget-size-box-item">
                                        <div className="input-m-3 ">
                                            <Input

                                                type="number"
                                                name="xgQuantity"
                                                handleOnChange={handleChangeSize}
                                                value={quantitySize.xgQuantity || ""}

                                            />
                                        </div>
                                    </div>
                                    <div className="budget-size-box-item">
                                        <div className="input-m-3 ">
                                            <div className='input-block'> R$ {calcSizePrice(selectedCategoryItem.inCashPrice, selectedCategoryItem.inCash, additionalSizePrice.xgPrice, quantitySize.xgQuantity)} </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="budget-size-box">
                                    <div className="budget-size-box-item-name">XXG</div>
                                    <div className="budget-size-box-item">
                                        <div className="input-m-3 ">
                                            <Input

                                                type="number"
                                                name="xxgQuantity"
                                                handleOnChange={handleChangeSize}
                                                value={quantitySize.xxgQuantity || ""}

                                            />
                                        </div>
                                    </div>
                                    <div className="budget-size-box-item">
                                        <div className="input-m-3 ">
                                            <div className='input-block'> R$ {calcSizePrice(selectedCategoryItem.inCashPrice, selectedCategoryItem.inCash, additionalSizePrice.xxgPrice, quantitySize.xxgQuantity)} </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="budget-size-box">
                                    <div className="budget-size-box-item-name">XXXG</div>
                                    <div className="budget-size-box-item">
                                        <div className="input-m-3 ">
                                            <Input

                                                type="number"
                                                name="xxxgQuantity"
                                                handleOnChange={handleChangeSize}
                                                value={quantitySize.xxxgQuantity || ""}

                                            />
                                        </div>
                                    </div>
                                    <div className="budget-size-box-item">
                                        <div className="input-m-3 ">
                                            <div className='input-block'> R$ {calcSizePrice(selectedCategoryItem.inCashPrice, selectedCategoryItem.inCash, additionalSizePrice.xxxgPrice, quantitySize.xxxgQuantity)} </div>
                                        </div>
                                    </div>
                                </div>

                            </div>



                            <div className="budget-subitem">
                                <div className="form-category-col-1">

                                    <div className='input-m-3 form-group'>
                                        <label>Quantidade</label>
                                        <div className='input-block'> {totalCalcQuantity()} </div>
                                    </div>

                                    <div className='input-m-2 form-group'>
                                        <label>Total à vista</label>
                                        <div className='input-block'> R$ {totalCalcInCash()} </div>
                                    </div>
                                    <div className='input-m-2 form-group'>
                                        <label>Total parcelado</label>
                                        <div className='input-block'> R$ {totalCalcInstalment()} </div>
                                    </div>



                                </div>
                            </div>
                            <div>Com desconto</div>
                            <div className="budget-subitem">
                                <div className='input-m-2 form-group'>
                                    <label className="input-label">Desconto e Comissão:</label>
                                    <Select
                                        options={optionsDiscount}
                                        styles={selectStyles}
                                        onChange={handleSelectChangeCommission}
                                        value={selectCommission}
                                    />


                                </div>
                            </div>
                            <div className="budget-subitem">
                                <div className="form-category-col-1">

                                    <div className='input-m-3 form-group'>
                                        <label>Quantidade</label>
                                        <div className='input-block'> {totalCalcQuantity()} </div>
                                    </div>

                                    <div className='input-m-2 form-group'>
                                        <label>Total à vista</label>
                                        <div className='input-block'> R$ {totalCalcDiscount(totalCalcInCash())} </div>
                                    </div>
                                    <div className='input-m-2 form-group'>
                                        <label>Total parcelado</label>
                                        <div className='input-block'> R$ {totalCalcDiscount(totalCalcInstalment())} </div>
                                    </div>
                                    <div className='input-m-2 form-group'>
                                        <label>Comissão</label>
                                        <div className='input-block'> R$ {totalCalcCommission(totalCalcInstalment())} </div>
                                    </div>



                                </div>
                            </div>
                        </div>
                        <div className="form-group-m1 form-group-left ">


                            <div className="bt-model-add bt-width-m2 bt-model-2" onClick={() => { editItemToBudget() }}> Editar </div>

                            <div className="bt-model-cancel  bt-width-m2 bt-model-2" onClick={() => { setModalBudgetItemEdit("modal-display-none") }}>Cancelar</div>
                        </div>
                    </div>


                </div>
            </div >

            {/* CONFIRM DELETE MODAL*/}
            <div className={`category-modal-background ${modalBudgetItemDelete} `} >
                <div className="category-modal-category-item">
                    <div className="box-x"> <div onClick={() => { setModalBudgetItemDelete("modal-display-none") }} className="x">X</div></div>
                    <div className='modal-additive-confirm-delete'>
                        <div className='modal-additive-confirm-delete-text'>Deseja realmente apagar as informações a baixo ?</div>
                        <div className=''>
                            <table>
                                <thead className="box-itens-thead">
                                    <tr>
                                        <th>Código</th>
                                        <th>Quant.</th>
                                        <th>Descrição</th>
                                        <th>Detalhes</th>

                                        <th>Des% Com%</th>
                                        <th>Valor Total</th>
                                        <th>Valor Total</th>
                                        <th>Comissão</th>



                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th>á vista</th>
                                        <th>parcelado</th>
                                        <th></th>

                                    </tr>
                                </thead>

                                <tbody className="box-itens-tbody">

                                    {budgetItemDelete &&
                                        (
                                            <tr>
                                                <td > <div className="box-itens-tbody-td">{budgetItemDelete.code}</div>  </td>
                                                <td > <div className="box-itens-tbody-td">{budgetItemDelete.totalQuantity}</div>  </td>
                                                <td > <div className="box-itens-tbody-td">{budgetItemDelete.selectedCategory.description}</div>  </td>
                                                <td > <div className="box-itens-tbody-td">{budgetItemDelete.selectedCategoryItem.description} , <span className="budget-item-color ">{budgetItemDelete.selectedCategoryItem.color}</span>,
                                                    {budgetItemDelete.selectedCategoryItem.sex.label === "Masculino" ?
                                                        <span className="budget-item-sex-m ">{budgetItemDelete.selectedCategoryItem.sex.label}</span> :
                                                        <span className="budget-item-sex-f ">{budgetItemDelete.selectedCategoryItem.sex.label}</span>
                                                    }
                                                    {budgetItemDelete.additiveArray &&

                                                        budgetItemDelete.additiveArray.map((addi) =>
                                                            <span>
                                                                {addi.checked === true ? <span className="budget-item-additive-with">{`com ${addi.additiveName}`} </span> : <span className="budget-item-additive-without">{`sem ${addi.additiveName}`} </span>}
                                                            </span>

                                                        )
                                                    }



                                                </div>  </td>

                                                <td > <div className="box-itens-tbody-td">{`${budgetItemDelete.selectCommission.discount}% ${budgetItemDelete.selectCommission.commission}% `}</div>  </td>

                                                <td > <div className="box-itens-tbody-td">R${budgetItemDelete.totalInCash}</div>  </td>
                                                <td > <div className="box-itens-tbody-td">R${budgetItemDelete.totalInstalment}</div>  </td>
                                                <td > <div className="box-itens-tbody-td">R${budgetItemDelete.totalCommission}</div>  </td>

                                                <td ><div className=' box-itens-tbody-td-action'>

                                                </div></td>
                                            </tr>

                                        )

                                    }

                                </tbody>

                            </table>
                        </div>
                        <div className='bt-model-add bt-model-1 bt-width-m2' onClick={() => { deleteBudgetItem() }} > Apagar</div>
                    </div>

                </div>

            </div >


        </div>
    )
}

export default FormBudget