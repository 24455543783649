import './formAdditionalSize.css'
import Input from '../../../shared/components/form/input'
import { Link } from "react-router-dom"
import { useState, useEffect } from 'react'

import api from '../../../utils/api'
import useFlashMessage from '../../../hooks/useFlashMessage'

//functions
import NumberToMoney from '../../../shared/functions/NumberToMoney'
import RandomString from '../../../shared/functions/RandomString'

function FormAdditionalSize({ handleUpdate, data }) {



    
    
  
    const [newAdditionalSize, setNewAdditionalSize] = useState(data || {})
    
    
   
  

    
   
    


    function handleChangeMoney(e) {

        let value = NumberToMoney(e.target.value)

        setNewAdditionalSize({ ...newAdditionalSize, [e.target.name]: value })


    }
   
    


    
    return (
        <section className='modal-section'>
            <div className='modal-additional-size'>
                <div className='modal-additional-size-line'>
                    <div className='modal-additional-size-name'>
                        P
                    </div>

                    <div className='modal-additional-size-price'>
                        <div className='RS'>R$</div>
                        <Input

                            type="number"
                            name="pPrice"
                            handleOnChange={handleChangeMoney}
                            value={newAdditionalSize.pPrice || ""}
                        />
                    </div>
                </div>

                <div className='modal-additional-size-line'>
                    <div className='modal-additional-size-name'>
                        M
                    </div>

                    <div className='modal-additional-size-price'>
                        <div className='RS'>R$</div>
                        <Input

                            type="number"
                            name="mPrice"
                            handleOnChange={handleChangeMoney}
                            value={newAdditionalSize.mPrice || ""}
                        />
                    </div>
                </div>

                <div className='modal-additional-size-line'>
                    <div className='modal-additional-size-name'>
                        XG
                    </div>

                    <div className='modal-additional-size-price'>
                        <div className='RS'>R$</div>
                        <Input

                            type="number"
                            name="xgPrice"
                            handleOnChange={handleChangeMoney}
                            value={newAdditionalSize.xgPrice || ""}
                        />
                    </div>
                </div>
                <div className='modal-additional-size-line'>
                    <div className='modal-additional-size-name'>
                        XXG
                    </div>

                    <div className='modal-additional-size-price'>
                        <div className='RS'>R$</div>
                        <Input

                            type="number"
                            name="xxgPrice"
                            handleOnChange={handleChangeMoney}
                            value={newAdditionalSize.xxgPrice || ""}
                        />
                    </div>
                </div>
                <div className='modal-additional-size-line'>
                    <div className='modal-additional-size-name'>
                        XXXG
                    </div>

                    <div className='modal-additional-size-price'>
                        <div className='RS'>R$</div>
                        <Input

                            type="number"
                            name="xxxgPrice"
                            handleOnChange={handleChangeMoney}
                            value={newAdditionalSize.xxxgPrice || ""}
                        />
                    </div>
                </div>


            </div>
            <div className='additional-size-box-bt'>
            <div className='bt-model-add bt-model-1 bt-width-m2' onClick={() => { handleUpdate(newAdditionalSize) }} > Salvar</div>
            </div>

          
           
        </section>
    )
}

export default FormAdditionalSize


