import { useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import Input from "../../../shared/components/form/input"
/* CSS */
import './login.css'

/* img */
import logo from '../../../shared/img/logo.png'


/* contexts */
import { Context } from '../../../context/UserContext'


function Login() {
  const [user, setUser] = useState({})
  const { login } = useContext(Context)

  function handleChange(e) {
    setUser({ ...user, [e.target.name]: e.target.value })
  }

  function handleSubmit(e) {
    e.preventDefault()
    
    login(user)
    
  }

  return (
    <section className="login-container">
     <div className="login-center">
     <img className='login-logo' src={logo} />
     
      <form onSubmit={handleSubmit} className='input-login-form'>
        <Input
          text="Usuário"
          type="text"
          name="name"
          placeholder="Digite o seu nome"
          handleOnChange={handleChange}
        />
        
        <Input
          text="Senha"
          type="password"
          name="password"
          placeholder="Digite a sua senha"
          handleOnChange={handleChange}
        />
        
        <input type="submit" value="Entrar" />

       
      </form>
      </div>
    </section>
  )
}

export default Login