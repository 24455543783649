import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import Select from 'react-select'

import api from '../../../utils/api'
import './categoryModal.css'

/* form */
import Input from "../../../shared/components/form/input"


import FormCloth from "./FormCloth"
import FormProvider from "./FormProvider"
import FormProduct from "./FormProduct"
import FormAdditive from "./FormAdditive"
import FormAdditionalSize from "./FormAdditionalSize"
import FormCommission from "./FormCommission"
import FormItem from "./FormItem"
import FormItemEdit from "./FormItemEdit"
import FormCategoryItem from "./FormCategoryItem"

function FormCategory({ handleSubmit, categoryData, btnText, btnCancelLink, title }) {

    const additionalSizeDefault = {

        pPrice: '0.00',
        mPrice: '0.00',
        xgPrice: '0.00',
        xxgPrice: '0.00',
        xxxgPrice: '0.00',



    }

    const [token] = useState(localStorage.getItem('token') || '')
    const [category, setCategory] = useState(categoryData || '')
    const [cloth, setCloth] = useState()
    const [provider, setProvider] = useState()
    const [product, setProduct] = useState()
    const [additive, setAdditive] = useState([])
    const [additionalSize, setAdditionalSize] = useState(additionalSizeDefault)
    const [categoryItem, setCategoryItem] = useState([])
    const [commission, setCommission] = useState([])
    const [modalCloth, setModalCloth] = useState('modal-display-none')
    const [modalProvider, setModalProvider] = useState('modal-display-none')
    const [modalProduct, setModalProduct] = useState('modal-display-none')
    const [modalAdditive, setModalAdditive] = useState('modal-display-none')
    const [modalAdditionalSize, setModalAdditionalSize] = useState('modal-display-none')
    const [modalCommission, setModalCommission] = useState('modal-display-none')
    const [modalItem, setModalItem] = useState('modal-display-none')
    const [modalItemEditData, setModalItemEditData] = useState("")
    const [modalItemEdit, setModalItemEdit] = useState('modal-display-none')
    const [dataStatus, setDataStatus] = useState(false)

    console.log("dataStatus")
    console.log(dataStatus)
    console.log('additive')
    console.log(additive)
    useEffect(() => {
        api.get('/category/getinfo', {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`
            }
        })
            .then((response) => {
                setCloth(response.data.cloth)
                setProvider(response.data.provider)
                setProduct(response.data.product)
                if (categoryData) {
                    setDataStatus(true)
                    setAdditive(categoryData.additive)
                    setAdditionalSize(categoryData.additionalSize)
                    setCategoryItem(categoryData.item)
                    
                    setCommission(categoryData.commission)
                }
            })
    }, [token])
    function updateClothSelect(data) {

        setCloth(data)
    }
    function updateProviderSelect(data) {

        setProvider(data)
    }
    function updateProductSelect(data) {

        setProduct(data)
    }
    function updateAdditive(data) {
        setAdditive(data)

    }
    function updateAdditionalSize(data) {
        setAdditionalSize(data)
        setModalAdditionalSize('modal-display-none')
    }
    function updateCommission(data) {
        setCommission(data)

    }
    function updateCategoryItem(data) {
        setCategoryItem(data)
    }
    function handleChange(e) {

        setCategory({ ...category, [e.target.name]: e.target.value })


    }

    const handleSelectChange = (selectedOption) => {
        console.log(selectedOption)
        setCategory({ ...category, [selectedOption.name]: selectedOption })
    }


    const selectStyles = {
        control: (styles) => ({ ...styles, borderColor: "black", borderRadius: "10px", border: "1px solid #9e9e9e", height: "41px" }),
    };
    let optionsCloth
    if (cloth) {
        optionsCloth = cloth.map((item) => (
            { ...optionsCloth, name: "cloth", value: item.clothName, label: item.clothName }
        )
        )
    }
    let optionsProvider
    if (provider) {
        optionsProvider = provider.map((item) => (
            { ...optionsProvider, name: "provider", value: item.providerName, label: item.providerName }
        )
        )
    }
    let optionsProduct
    if (product) {
        optionsProduct = product.map((item) => (
            { ...optionsProduct, name: "product", value: item.productName, label: item.productName }
        )
        )
    }

    function orderAdditionalSize() {

    }

    function submit(e) {
        e.preventDefault()
        let data = category
        data = { ...data, additive: additive, additionalSize: additionalSize, commission: commission, item: categoryItem }


        console.log(data)
         handleSubmit(data)

    }
    function addItem() {
        setModalItem("modal-display-flex")

    }
    function editItem(item) {
        setModalItemEditData(item)
        setModalItemEdit("modal-display-flex")

    }
    function editItemById(data) {

        let array = categoryItem.map((i) => {
            if (i._id === data.id) {
                return [data]

            } else {
                return i
            }
        })
        console.log(array)

    }


    return (
        <div className="container-form-client">
            <div className="center-form-client">
                <h1 className='default-title-m1'>{title}</h1>
                <form onSubmit={submit} className="form-box-client ">
                    <div className="form-category-col-1">
                        <div className="input-m-3 form-group">
                            <Input

                                text="Código"
                                type="text"
                                name="code"
                                placeholder="digite o nome do categorye"
                                handleOnChange={handleChange}
                                value={category.code || ''}
                            />
                        </div>
                        <div className="input-m-2 form-group">
                            <label className="input-label">Tecido:
                                <div onClick={() => { setModalCloth("category-modal-display-flex") }}>
                                    <div className="plus-sinal" >
                                        <div className="plus-sinal-tip"></div>
                                        <div className="plus-sinal-middle"></div>
                                        <div className="plus-sinal-tip"></div>
                                    </div>
                                </div>
                            </label>
                            <Select
                                options={optionsCloth}
                                styles={selectStyles}
                                onChange={handleSelectChange}
                                value={category.cloth || ""}

                            />
                        </div>
                        <div className="input-m-2 form-group">
                            <label className="input-label">Fornecedor:
                                <div onClick={() => { setModalProvider("category-modal-display-flex") }} >
                                    <div className="plus-sinal" >
                                        <div className="plus-sinal-tip"></div>
                                        <div className="plus-sinal-middle"></div>
                                        <div className="plus-sinal-tip"></div>
                                    </div>
                                </div>
                            </label>
                            <Select
                                options={optionsProvider}
                                styles={selectStyles}
                                onChange={handleSelectChange}
                                value={category.provider || ""}

                            />
                        </div>
                        <div className="input-m-2 form-group">
                            <label className="input-label">Produto:
                                <div onClick={() => { setModalProduct("category-modal-display-flex") }} >
                                    <div className="plus-sinal" onClick={() => { setModalProduct("category-modal-display-flex") }}>
                                        <div className="plus-sinal-tip"></div>
                                        <div className="plus-sinal-middle"></div>
                                        <div className="plus-sinal-tip"></div>
                                    </div>
                                </div>
                            </label>
                            <Select
                                options={optionsProduct}
                                styles={selectStyles}
                                onChange={handleSelectChange}
                                value={category.product || ""}

                            />
                        </div>
                        <div className="input-m-1 form-group">
                            <Input

                                text="Descrição"
                                type="text"
                                name="description"
                                placeholder="digite uma descrição"
                                handleOnChange={handleChange}
                                value={category.description || ''}
                            />
                        </div>

                    </div>


                    <div>
                        <div className="input-m-2 form-group">
                            <label className="input-label">Aditivo:
                                <div onClick={() => { setModalAdditive("category-modal-display-flex") }} >
                                    <div className="plus-sinal" >
                                        <div className="plus-sinal-tip"></div>
                                        <div className="plus-sinal-middle"></div>
                                        <div className="plus-sinal-tip"></div>
                                    </div>
                                </div>
                            </label>
                        </div>
                        <div className="additive">

                            {
                                additive.map((item) =>
                                    <div className="additive-item" key={item._id}>
                                        <div className="additive-item-name">{item.additiveName}:</div><div className="additive-item-price">R$ {item.price}</div>
                                    </div>
                                )
                            }
                        </div>

                    </div>

                    <div>
                        <div className="input-m-2 form-group">
                            <label className="input-label">Adicional de tamanho:
                                <div onClick={() => { setModalAdditionalSize("category-modal-display-flex") }}>
                                    <div className="plus-sinal" >
                                        <div className="plus-sinal-tip"></div>
                                        <div className="plus-sinal-middle"></div>
                                        <div className="plus-sinal-tip"></div>
                                    </div>
                                </div>

                            </label>
                        </div>
                        <div className="additional-size">
                            <div className="additional-size-item" >
                                <div className="additional-size-item-name">P</div>:<div className="additional-size-item-price">R$ {additionalSize.pPrice}</div>
                            </div>
                            <div className="additional-size-item" >
                                <div className="additional-size-item-name">M</div>:<div className="additional-size-item-price">R$ {additionalSize.mPrice}</div>
                            </div>
                            <div className="additional-size-item" >
                                <div className="additional-size-item-name">XG</div>:<div className="additional-size-item-price">R$ {additionalSize.xgPrice}</div>
                            </div>
                            <div className="additional-size-item" >
                                <div className="additional-size-item-name">XXG</div>:<div className="additional-size-item-price">R$ {additionalSize.xxgPrice}</div>
                            </div>
                            <div className="additional-size-item" >
                                <div className="additional-size-item-name">XXXG</div>:<div className="additional-size-item-price">R$ {additionalSize.xxxgPrice}</div>
                            </div>
                        </div>

                    </div>

                    <div>
                        <div className="input-m-2 form-group">
                            <label className="input-label">Comissão e desconto:
                                <div onClick={() => { setModalCommission("category-modal-display-flex") }}>
                                    <div className="plus-sinal">
                                        <div className="plus-sinal-tip"></div>
                                        <div className="plus-sinal-middle"></div>
                                        <div className="plus-sinal-tip"></div>
                                    </div>
                                </div>
                            </label>
                        </div>
                        <div className="commission">
                            {
                                commission.map((item) =>
                                    <div className="commission-item" key={item._id}>
                                        <div className="commission-item-commission">Com.{item.commissionValue}%:</div><div className="additive-item-discount">Des. {item.discountValue}%</div>
                                    </div>
                                )
                            }
                        </div>

                    </div>

                    {dataStatus ?
                        <>
                            <FormCategoryItem handleUpdate={updateCategoryItem} data={categoryData.item} />
                        </>
                        :
                        <FormCategoryItem handleUpdate={updateCategoryItem} />
                    }
                    <div className="form-group-m1 form-group-right ">

                        <Link className="link-default" to={btnCancelLink}><div className="bt-model-cancel  bt-width-m2 bt-model-2">Cancelar</div></Link>
                        <input className="bt-model-add bt-width-m2 bt-model-2" type="submit" value={btnText} />
                    </div>
                </form>
            </div>


            {/* CLOTH MODAL */}
            <div className={`category-modal-background ${modalCloth} `} >
                <div className="category-modal">
                    <div className="box-x"> <div onClick={() => { setModalCloth("modal-display-none") }} className="x">X</div></div>

                    <FormCloth handleUpdate={updateClothSelect} />

                </div>

            </div >
            {/* PROVIDER MODAL */}
            <div className={`category-modal-background ${modalProvider} `} >
                <div className="category-modal">
                    <div className="box-x"> <div onClick={() => { setModalProvider("modal-display-none") }} className="x">X</div></div>
                    <FormProvider handleUpdate={updateProviderSelect} />
                </div>

            </div >
            {/* PRODUCT MODAL */}
            <div className={`category-modal-background ${modalProduct} `} >
                <div className="category-modal">
                    <div className="box-x"> <div onClick={() => { setModalProduct("modal-display-none") }} className="x">X</div></div>
                    <FormProduct handleUpdate={updateProductSelect} />
                </div>

            </div >
            {/*ADDITIVE MODAL */}
            <div className={`category-modal-background ${modalAdditive} `} >
                <div className="category-modal">
                    <div className="box-x"> <div onClick={() => { setModalAdditive("modal-display-none") }} className="x">X</div></div>
                    {dataStatus ?
                        <>
                            <FormAdditive handleUpdate={updateAdditive} data={categoryData.additive}/>
                        </>
                        :
                        <FormAdditive handleUpdate={updateAdditive} />
                    }


                </div>

            </div >

            {/*ADDICIONAL SIZE MODAL */}
            <div className={`category-modal-background ${modalAdditionalSize} `} >
                <div className="category-modal">
                    <div className="box-x"> <div onClick={() => { setModalAdditionalSize("modal-display-none") }} className="x">X</div></div>
                   
                   {dataStatus?
                   <>
                   <FormAdditionalSize handleUpdate={updateAdditionalSize} data={categoryData.additionalSize} />
                   </>
                   :
                   <FormAdditionalSize handleUpdate={updateAdditionalSize} />
                    
                   }
                    
                </div>

            </div >
            {/*COM AND DES */}
            <div className={`category-modal-background ${modalCommission} `} >
                <div className="category-modal">
                    <div className="box-x"> <div onClick={() => { setModalCommission("modal-display-none") }} className="x">X</div></div>
                    {dataStatus?
                <>
                <FormCommission handleUpdate={updateCommission} data={categoryData.commission} />
                </>    
                :
                <FormCommission handleUpdate={updateCommission} />
                }
                    
                </div>

            </div >
            {/*ITEM */}
            <div className={`category-modal-background ${modalItem} `} >
                <div className="category-modal-item">
                    <div className="box-x"> <div onClick={() => { setModalItem("modal-display-none") }} className="x">X</div></div>




                </div>

            </div >
            {/*ITEM Edit */}
            <div className={`category-modal-background ${modalItemEdit} `} >
                <div className="category-modal-item">
                    <div className="box-x"> <div onClick={() => { setModalItemEdit("modal-display-none") }} className="x">X</div></div>
                    {modalItemEditData &&
                        <FormItemEdit handleUpdate={editItemById} data={modalItemEditData} />

                    }


                </div>

            </div >











        </div>
    )
}

export default FormCategory