import api from '../../../utils/api'
import { useState, useEffect } from "react"
import { useParams } from 'react-router-dom'
import generatePDF, { Margin } from 'react-to-pdf'

/* img */
import logo from '../../../shared/img/logo.png'
/* functions */
import TimestampToString from '../../../shared/functions/TimestampToString'

import './budgetPDF.css'
const configPDF = {
    //Baixar/Salver = save / Abrir no navegador = open
    method: 'open',
    page: {
        //definir a margem : SMALL ou MEDIUM
        margin: Margin.MEDIUM,
        format: 'A4',
        orientation: 'portrait',


    }
}

const contentToPDF = () => document.getElementById('content-id')

function BudgetPDF() {
    const [budgetInfo, setBudgetInfo] = useState({})
    const [budgetItem, setBudgetItem] = useState([])
    const [client, setClient] = useState({})
    const [token] = useState(localStorage.getItem('token') || '')
    const { id } = useParams()
    const { timeStampToDateString } = TimestampToString()

    console.log(budgetInfo)
    useEffect(() => {
        api.get(`/budget/getinfo/${id}`, {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`
            }
        })
            .then((response) => {
                setBudgetInfo(response.data.budget)
                setClient(response.data.client)
                setBudgetItem(response.data.budget.budgetItemArray)


            })
    }, [token])


    return (
        <section>
            <div className='bt-model-3 bt-width-m2 ' onClick={() => { generatePDF(contentToPDF, configPDF) }}> Gerar PDF </div>
            <div className='budget-pdf-container' id="content-id">

                <div className='budget-pdf-line'>
                    <div className='form-group-left'>
                        <img className='budget-pdf-img-logo' src={logo} />
                    </div>

                </div>
                <div className='budget-pdf-line'>
                    <div className='form-group-right budget-spacing'>
                        <div><span className='budget-title-1'>{timeStampToDateString(budgetInfo.updatedAt)}</span> </div>
                    </div>

                </div>
                <div className='budget-pdf-line budget-margin'>
                    <div>
                        <div className='budget-spacing'><span className='budget-title-1'>Cliente:</span> <span className='budget-text-1'>{client.clientName}</span> </div>
                        <div className='budget-spacing'><span className='budget-title-1'>Nome do contato:</span> <span className='budget-text-1'>{client.contact}</span> </div>
                        <div className='budget-spacing'><span className='budget-title-1'>Contato:</span> <span className='budget-text-1'>{client.email}, {client.phone}</span> </div>
                    </div>

                </div>
                <div>
                    <table className='budget-table'>
                        <tr >

                            <th><div className='budget-table-th1'> Quantidade</div></th>
                            <th><div className='budget-table-th'> Descrição</div></th>
                            <th><div className='budget-table-th'> Detalhes </div></th>
                            <th><div className='budget-table-th'> Cor </div></th>
                            <th><div className='budget-table-th'> Ref </div></th>
                            <th><div className='budget-table-th'> Tecido</div></th>
                            <th><div className='budget-table-th2'> Valor</div></th>
                        </tr>

                        {budgetItem &&

                            budgetItem.map((i) => 
                                <tr key={i._id} > 
                                    <td><div className='budget-table-td1'> {i.totalQuantity} </div></td>
                                    <td><div className='budget-table-td'> { i.selectedCategory.description}</div></td>
                                    <td><div className='budget-table-td'> {i.selectedCategoryItem.description} </div></td>
                                    <td><div className='budget-table-td'> {i.selectedCategoryItem.color}  </div></td>
                                    <td><div className='budget-table-td'> {i.selectedCategoryItem.ref} </div></td>
                                    <td><div className='budget-table-td'> {i.selectedCategory.cloth.label}</div></td>
                                    <td><div className='budget-table-td2'> R$ {budgetInfo.paymentType.value === 'inCash' ? i.totalInCashWithDis : i.totalInstalmentWithDis }</div></td>
                                </tr>
                            )
                        }
                        

                        <tr>
                            <td><div className='budget-table-td1'> </div></td>
                            <td><div className='budget-table-td'> </div></td>
                            <td><div className='budget-table-td'>  </div></td>
                            <td><div className='budget-table-td'>  </div></td>
                            <td><div className='budget-table-td'>  </div></td>
                            <td><div className='budget-table-td'> </div></td>
                            <td><div className='budget-table-td2'> </div></td>
                        </tr>
                    </table>
                </div>
                <div className='form-group-right'>
                    <div className='budget-total-box  '>
                        <div className='budget-title-1'>
                            Total do Orçamento
                        </div>
                        <div className='budget-text-1'>R$ {budgetInfo.budgetPrice} </div>
                    </div>
                </div>

                <div className='budget-pdf-line budget-margin'>
                    <div>
                        <div className='budget-spacing'><span className='budget-title-1'>Previsão de entrega:</span> <span className='budget-text-1'>25 a 30 dias úteis</span> </div>
                        <div className='budget-spacing'><span className='budget-title-1'>Condições de pagamento:</span> <span className='budget-text-1'> 50% entrada e 50% contra entrega</span> </div>
                        <div className='budget-spacing'><span className='budget-title-1'>Frete:</span> <span className='budget-text-1'>{`(x)CIF  ( )FOB`}</span> </div>
                        <div className='budget-spacing'><span className='budget-title-1'>Validade da proposta:</span> <span className='budget-text-1'> 07 dias</span> </div>
                        <div className='budget-spacing'><span className='budget-title-1'>OBS: Nas compras inferiores a R$ 500,00 será incluído frete de R$ 40,00, ou o cliente poderá retirar a mercadoria no Local</span> </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default BudgetPDF