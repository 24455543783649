import './formCommission.css'
import Input from '../../../shared/components/form/input'
import { Link } from "react-router-dom"
import { useState, useEffect } from 'react'

import api from '../../../utils/api'
import useFlashMessage from '../../../hooks/useFlashMessage'

//functions
import NumberToMoney from '../../../shared/functions/NumberToMoney'
import RandomString from '../../../shared/functions/RandomString'

function FormCommission({ handleUpdate, data }) {



    
    const [modalCommissionEdit, setModalCommissionEdit] = useState('modal-display-none')
    const [modalCommissionDelete, setModalCommissionDelete] = useState('modal-display-none')
    const [commissionDeleteItem, setCommissionDeleteItem] = useState({})
    const [newCommission, setNewCommission] = useState({})
    const [commission, setCommission] = useState(data || [])
    const [editCommissionData, setEditCommissionData] = useState("")
    
    console.log("commission")
    console.log(commission)
    
 
    function editCommission(item) {

        setModalCommissionEdit("modal-display-flex")
        setEditCommissionData(item)

    }
    function updateEditCommission(id) {

        
        let data = commission.map((item) => {
             if (id === item._id) {
                 return {_id: editCommissionData._id , commissionValue: editCommissionData.commissionValue, discountValue: editCommissionData.discountValue}
             }else{
                 return item
             }
         }
         )
         setCommission(data)
         setModalCommissionEdit("modal-display-none")
         handleUpdate(data)
     }
    function removeCommission(item){
        setModalCommissionDelete("modal-display-flex")
        setCommissionDeleteItem(item)
    }

    function removeCommissionItem(id){

        const updatedArray = commission.filter((item) => {
            return item._id !== id
        })
        
        setCommission(updatedArray)
        handleUpdate(updatedArray)
        setModalCommissionDelete("modal-display-none")
    }



    function handleChange(e) {

       

        setNewCommission({ ...newCommission, [e.target.name]: e.target.value })


    }
    const handleChangeCommission = (e) => {

        setEditCommissionData({ ...editCommissionData, [e.target.name]: e.target.value })
    }
    async function addCommission(data) {
        
        let id = RandomString(16)
        data = {...data, _id: id}

        setCommission([...commission, data ])
        /* handleUpdate(commission) */
        let update = [...commission, data]
        handleUpdate(update)




    }
    
    
    function handleChange(e) {

        setNewCommission({ ...newCommission, [e.target.name]: e.target.value })
    }
    return (
        <section className='modal-section'>
            <div className='modal-commission'>
            <div className='modal-commission-price'>
                <Input
                    text="Comissão"
                    type="number"
                    name="commissionValue"
                    handleOnChange={handleChange}
                    value={newCommission.commissionValue}
                />
                <div className='RS'>%</div>
</div>
                <div className='modal-commission-price'>
                    
                    <Input
                        text="Desconto"
                        type="number"
                        name="discountValue"
                        handleOnChange={handleChange}
                        value={newCommission.discountValue || ""}
                    />
                    <div className='RS'>%</div>
                </div>
                <div className='bt-model-add bt-model-1 bt-width-m2' onClick={() => { addCommission(newCommission) }} > Adicionar</div>
            </div>

            <div className='modal-list'>
                {
                commission.map((item) =>
                <div key={item._id} className='modal-list-div'> <div>  Com. {item.commissionValue} % </div> <div> Dis.{item.discountValue}%</div> <div className=' box-itens-tbody-td-action'>
                    <button className='bt edit' title='Editar' onClick={() => { editCommission(item) }} ></button>
                    <button className='bt delete' title='Apagar' onClick={() => { removeCommission(item)}}></button>
    
                </div></div>
            )
                }
            </div>


            {/* CLOTH MODAL EDIT */}
            <div className={`category-modal-background ${modalCommissionEdit} `} >
                <div className="category-modal-small">
                    <div className="box-x"> <div onClick={() => { setModalCommissionEdit("modal-display-none") }} className="x">X</div></div>
                    <div className='modal-commission'>
                    <div className='modal-commission-price'>
                <Input
                    text="Comissão"
                    type="number"
                    name="commissionValue"
                    handleOnChange={handleChangeCommission}
                    value={editCommissionData.commissionValue}
                />
                <div className='RS'>%</div>
</div>
                <div className='modal-commission-price'>
                    
                    <Input
                        text="Desconto"
                        type="number"
                        name="discountValue"
                        handleOnChange={handleChangeCommission}
                        value={editCommissionData.discountValue || ""}
                    />
                    <div className='RS'>%</div>
                </div>
                        <div className='bt-model-add bt-model-1 bt-width-m2' onClick={()=> {updateEditCommission(editCommissionData._id)}}  > Salvar</div>
                    </div>

                </div>

            </div >
             {/* CONFIRM DELETE MODAL*/}
             <div className={`category-modal-background ${modalCommissionDelete} `} >
                <div className="category-modal-small">
                    <div className="box-x"> <div onClick={() => { setModalCommissionDelete("modal-display-none") }} className="x">X</div></div>
                    <div className='modal-commission-confirm-delete'>
                        <div>Deseja realmente apagar as informações a baixo ?</div>
                        
                        <div className="commission-item" >
                                        <div className="commission-item-commission">Com.{commissionDeleteItem.commissionValue}%:</div><div className="additive-item-discount">Des. {commissionDeleteItem.discountValue}%</div>
                                    </div>

                          <div className='modal-box-bt-model-1'>          
                        <div className='bt-model-cancel bt-model-1 bt-width-m2' onClick={()=> {setModalCommissionDelete("modal-display-none") }} > Cancelar</div>
                    
                        <div className='bt-model-add bt-model-1 bt-width-m2' onClick={()=> {removeCommissionItem(commissionDeleteItem._id)}} > Apagar</div>
                        </div>
                    </div>

                </div>

            </div >
        </section>
    )
}

export default FormCommission