import api from '../../../utils/api'

import { useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'

/* Hooks */
import useFlashMessage from '../../../hooks/useFlashMessage'

/* Form */
import FormBudget from '../components/FormBudget'

function NewBudget(){

    const [token] = useState(localStorage.getItem('token') || '')
    const {setFlashMessage} = useFlashMessage()
    const navigate = useNavigate()

async function registerBudget(obj){
    let msgType = 'success'
        

        
    const data = await api.post('/budget/new', obj, {
        Authorization: `Bearer ${JSON.parse(token)}`
    }).then((response) => {
        return response.data
    })
    .catch((err) => {
        msgType = 'error'
        return err.response.data
    })
        
    
  
  
    

setFlashMessage(data.message, msgType)

if (msgType !== 'error'){
    
 navigate('/budget/list')
}
}


    return (
        <section>
            <div className='default-container-m1'>
            <FormBudget title={'Novo Orçamento'}  handleSubmit={registerBudget}/>
            </div>
        </section>
    )
}

export default NewBudget