import api from '../../../utils/api'
import { useState, useEffect } from "react"
import { Link } from "react-router-dom"

/* CSS */
import './category.css'
import '../../../shared/components/form/inputDefault.css'

/* form */
import Input from "../../../shared/components/form/input"

/* Hooks */
import useFlashMessage from '../../../hooks/useFlashMessage'

/* Modal */
import ConfirmModal from "../../../shared/components/confirmModal/ConfirmModal"


function ListCategory() {
    const categoryDefault =
    {
        code: "",
        cloth: {
            name: "",
            value: "",
            label: "",
        },
        provider: {
            name: "",
            value: "",
            label: "",
        },
        product: {
            name: "",
            value: "",
            label: "",
        },
        additive: [],
        addicionalSize: [],
        commission: [],
        item: [],

    }


    const [category, setCategory] = useState([])
    const [token] = useState(localStorage.getItem('token') || '')
    const { setFlashMessage } = useFlashMessage()
    const [modalCategoryDelete, setModalCategoryDelete] = useState('modal-display-none')
    const [categoryDelete, setCategoryDelete] = useState(categoryDefault)


    /* Search */
    const [search, setSearch] = useState('')
    const searchLowerCase = search.toLowerCase()
    const categorySearch = category.filter((category) => category.description.toLowerCase().includes(searchLowerCase))
    function handleChange(e) {
        setSearch(e.target.value)


    }
    function handleOnSearch(e) {


    }

    function handleSubmit(e) {
        e.preventDefault()
        /*  register(user) */
    }


    useEffect(() => {
        api.get('/category/getall', {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`
            }
        })
            .then((response) => {
                setCategory(response.data.category)
            })
    }, [token])

    function confirmCategoryDelete(data) {
        setModalCategoryDelete('modal-display-flex')
        setCategoryDelete(data)
    }

    async function deleteCategory(id) {
        let msgType = 'success'



        const data = await api.delete(`/category/del/${id}`, {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`,
            },
        }).then((response) => {
            const updatedCategory = category.filter((category) => category._id != id)
            setCategory(updatedCategory)
            setModalCategoryDelete('modal-display-none')
            return response.data
        }).catch((err) => {

            msgType = 'error'
            return err.response.data
        })

        setFlashMessage(data.message, msgType)

    }



    return (
        <section className='default-container-m1'>

            <h1 className='default-title-m1'> Categorias</h1>
            <div className='defaut-search'>
                <Link className="link-default bt-model-3 bt-width-m2 " to="/category/new"> Nova Categoria</Link>
                <form >
                    <div className='bt-width-m1'>
                        <Input


                            type="text"
                            name="search"
                            placeholder="Buscar"
                            handleOnChange={handleChange}

                        />
                    </div>
                </form>
            </div>


            <div className='List'>
                {/* Search */}
                {search.length > 0 ?
                    categorySearch.map((category) => (

                        <div className='category-list' key={category._id}>
                                <div className='bold category-list-title'>
                                    <div className='category-list-title-code'>{category.code}</div>
                                    <div className='category-list-title-cloth'>{category.cloth.value}</div>
                                    <div className='category-list-title-provider'>{category.provider.value}</div>
                                    <div className='category-list-title-product'>{category.product.value}</div>
                                    <div className='category-list-title-description'>{category.description}</div>
                                    <div className='action category-list-title-action '>
                                        <Link className='' to={`/category/edit/${category._id}`}><button className='bt edit' title='Editar'></button></Link>
                                        <button className='bt delete' title='Apagar' onClick={() => { confirmCategoryDelete(category) }}></button>

                                    </div>
                                </div>
                                <div className='category-list-subitens'>
                                    <div className='category-list-subitens-header'>
                                        <div className='category-list-subitens-header-additive'> Aditivo: {category.additive.map((additive) => (<div className='category-list-subitens-additive-item'>{additive.additiveName}</div>))} </div>
                                        <div className='category-list-subitens-header-price'>
                                            <div className='category-list-subitens-itens-price-value'>Á vista</div>
                                            <div className='category-list-subitens-itens-price-value'>Parcelado</div>

                                        </div>
                                    </div>
                                    <div className='category-list-subitens-box'>
                                        {category.item.map((i) => (
                                            <div className='category-list-subitens-itens' key={i._id}>
                                                <div className='category-list-subitens-itens-description'> {i.description} </div>
                                                <div className='category-list-subitens-itens-color'> {i.color} </div>

                                                <div className='category-list-subitens-itens-sex'> {i.sex.label} </div>
                                                <div className='category-list-subitens-itens-price'>
                                                    <div className='category-list-subitens-itens-price-value'>R$ {i.inCashPrice} </div>
                                                    <div className='category-list-subitens-itens-price-value'>R$ {i.instalmentPrice} </div>
                                                </div>

                                            </div>
                                        ))}
                                    </div>
                                </div>


                            </div>


                    )) : category.length > 0 ?
                        category.map((category) => (

                            <div className='category-list' key={category._id}>
                                <div className='bold category-list-title'>
                                    <div className='category-list-title-code'>{category.code}</div>
                                    <div className='category-list-title-cloth'>{category.cloth.value}</div>
                                    <div className='category-list-title-provider'>{category.provider.value}</div>
                                    <div className='category-list-title-product'>{category.product.value}</div>
                                    <div className='category-list-title-description'>{category.description}</div>
                                    <div className='action category-list-title-action '>
                                        <Link className='' to={`/category/edit/${category._id}`}><button className='bt edit' title='Editar'></button></Link>
                                        <button className='bt delete' title='Apagar' onClick={() => { confirmCategoryDelete(category) }}></button>

                                    </div>
                                </div>
                                <div className='category-list-subitens'>
                                    <div className='category-list-subitens-header'>
                                        <div className='category-list-subitens-header-additive'> Aditivo: {category.additive.map((additive) => (<div className='category-list-subitens-additive-item'>{additive.additiveName}</div>))} </div>
                                        <div className='category-list-subitens-header-price'>
                                            <div className='category-list-subitens-itens-price-value'>Á vista</div>
                                            <div className='category-list-subitens-itens-price-value'>Parcelado</div>

                                        </div>
                                    </div>
                                    <div className='category-list-subitens-box'>
                                        {category.item.map((i) => (
                                            <div className='category-list-subitens-itens' key={i._id}>
                                                <div className='category-list-subitens-itens-description'> {i.description} </div>
                                                <div className='category-list-subitens-itens-color'> {i.color} </div>

                                                <div className='category-list-subitens-itens-sex'> {i.sex.label} </div>
                                                <div className='category-list-subitens-itens-price'>
                                                    <div className='category-list-subitens-itens-price-value'>R$ {i.inCashPrice} </div>
                                                    <div className='category-list-subitens-itens-price-value'>R$ {i.instalmentPrice} </div>
                                                </div>

                                            </div>
                                        ))}
                                    </div>
                                </div>


                            </div>

                        )) : category.length === 0 && <p> Não há categorias cadastrados</p>}


            </div>
            {/* CONFIRM DELETE MODAL*/}
            <div className={`category-modal-background ${modalCategoryDelete} `} >
                <div className="category-modal-category-item">
                    <div className="box-x"> <div onClick={() => { setModalCategoryDelete("modal-display-none") }} className="x">X</div></div>
                    <div className='modal-additive-confirm-delete'>
                        <div className='modal-additive-confirm-delete-text'>Deseja realmente apagar a categoria a baixo ?</div>
                        <div className=''>
                            {categoryDelete &&
                                <div className='category-list'>
                                    <div className='bold category-list-title'>
                                        <div className='category-list-title-code'>{categoryDelete.code}</div>
                                        <div className='category-list-title-cloth'>{categoryDelete.cloth.value}</div>
                                        <div className='category-list-title-provider'>{categoryDelete.provider.value}</div>
                                        <div className='category-list-title-product'>{categoryDelete.product.value}</div>
                                        <div className='category-list-title-description'>{categoryDelete.description}</div>
                                        <div className='action category-list-title-action '>

                                        </div>
                                    </div>

                                    <div className='category-list-subitens'>
                                        <div className='category-list-subitens-header'>
                                            <div className='category-list-subitens-header-additive'> Aditivo: {categoryDelete.additive.map((additive) => (<div className='category-list-subitens-additive-item'>{additive.additiveName}</div>))} </div>
                                            <div className='category-list-subitens-header-price'>
                                                <div className='category-list-subitens-itens-price-value'>Á vista</div>
                                                <div className='category-list-subitens-itens-price-value'>Parcelado</div>

                                            </div>
                                        </div>
                                        {categoryDelete.item &&
                                            <div className='category-list-subitens-box'>
                                                {categoryDelete.item.map((i) => (
                                                    <div className='category-list-subitens-itens' key={i._id}>
                                                        <div className='category-list-subitens-itens-description'> {i.description} </div>
                                                        <div className='category-list-subitens-itens-color'> {i.color} </div>

                                                        <div className='category-list-subitens-itens-sex'> {i.sex.label} </div>
                                                        <div className='category-list-subitens-itens-price'>
                                                            <div className='category-list-subitens-itens-price-value'>R$ {i.inCashPrice} </div>
                                                            <div className='category-list-subitens-itens-price-value'>R$ {i.instalmentPrice} </div>
                                                        </div>

                                                    </div>
                                                ))}
                                            </div>
                                        }
                                    </div>


                                </div>
                            }


                        </div>
                        <div className='bt-model-add bt-model-1 bt-width-m2' onClick={() => { deleteCategory(categoryDelete._id) }} > Apagar</div>
                    </div>

                </div>

            </div >


        </section>
    )
}

export default ListCategory