import api from '../../../utils/api'

import { useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'



/* Hooks */
import useFlashMessage from '../../../hooks/useFlashMessage'
import FormCategory from '../components/FormCategory'

function NewCategory(){
    const [token] = useState(localStorage.getItem('token') || '')
    const {setFlashMessage} = useFlashMessage()
    const navigate = useNavigate()
   
    async function registerCategory(category) {
        let msgType = 'success'
        

        
            const data = await api.post('/category/new', category, {
                Authorization: `Bearer ${JSON.parse(token)}`
            }).then((response) => {
                return response.data
            })
            .catch((err) => {
                msgType = 'error'
                return err.response.data
            })
                
            
          
          
            
        
        setFlashMessage(data.message, msgType)
        
        if (msgType !== 'error'){
            
        navigate('/category')
        }
    }

    return(
        
        <section >
            <div className='default-container-m1'>
        <FormCategory title={'Nova Categoria'} handleSubmit={registerCategory} btnText="Cadastrar" />
        </div>
        </section>
    )
}

export default NewCategory