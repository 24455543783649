import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import './App.css';

//components
import Navbar from "./shared/navbar/navbar";
import Message from "./shared/components/FlashMessage/Message"


/* private pages */
import PrivateRoute from './shared/privateRoute/PrivateRoute';


//pages
import Login from "./pages/auth/pages/Login"
import Register from "./pages/auth/pages/Register"
import Home from './pages/home/Home'

//client
import Client from "./pages/client/Client"
import NewClient from "./pages/client/NewClient"
import EditClient from "./pages/client/EditClient"

//category
import ListCategory from './pages/category/pages/ListCategory';
import NewCategory from './pages/category/pages/NewCategory';
import EditCategory from './pages/category/pages/EditCategory'

//budget
import NewBudget from './pages/budget/pages/NewBudget';
import ListBudget from './pages/budget/pages/ListBudget';
import EditBudget from './pages/budget/pages/EditBudget';
import BudgetPDF from './pages/budget/pages/BudgetPDF';

/* context */
import { UserProvider } from './context/UserContext';

function App() {
  return (
    <Router>
      <UserProvider>
        <Navbar />
        <Message />
          <Routes>
            <Route path="/login" exact element={<Login />} />
            <Route path="/register" exact element={<Register/>} />
            <Route path='/' exact element={<Home/>} />

            
            <Route element={<PrivateRoute />}>

            <Route path='/client' exact element={<Client />} />
            <Route path='/client/new' exact element={<NewClient />} />
            <Route path='/client/edit/:id' exact element={<EditClient />} />
              
            <Route path='/category' exact element={<ListCategory/>} /> 
            <Route path='/category/new'exact element={<NewCategory/>} />
            <Route path='/category/edit/:id'exact element={<EditCategory/>} />

            <Route path='/budget/new' exact element={<NewBudget/>} />
            <Route path='/budget/list' exact element={<ListBudget/>} />
            <Route path='/budget/edit/:id' exact element={<EditBudget/>} />
            <Route path='/budget/pdf/:id' exact element={<BudgetPDF/>} />

            </Route>
          </Routes>


        
      </UserProvider>
    </Router>
  );
}

export default App;
