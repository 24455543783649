import api from '../../utils/api'
import { useState, useEffect } from "react"
import { Link } from "react-router-dom"

/* CSS */
import './client.css'
import '../../shared/components/form/inputDefault.css'

/* form */
import Input from "../../shared/components/form/input"

/* Hooks */
import useFlashMessage from '../../hooks/useFlashMessage'

/* Modal */
import ConfirmModal from "../../shared/components/confirmModal/ConfirmModal"


function ListClient() {
    const [clients, setClients] = useState([])
    const [token] = useState(localStorage.getItem('token') || '')
    const { setFlashMessage } = useFlashMessage()

    /* Modal topo*/
    const [modal, setModal] = useState(false)
    const [id, setId] = useState()
    const [name, setName] = useState('')
    const handleOnclick = (id, name) => {
        setModal(true)
        setId(id)
        setName(name)


    }
    /* Search */
    const [search, setSearch] = useState('')
    const searchLowerCase = search.toLowerCase()
    const clientSearch = clients.filter((client) => client.clientName.toLowerCase().includes(searchLowerCase))
    function handleChange(e) {
        setSearch(e.target.value)
        console.log(search)

    }
    function handleOnSearch(e) {


    }

    function handleSubmit(e) {
        e.preventDefault()
        /*  register(user) */
    }


    useEffect(() => {
        api.get('/client/getall', {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`
            }
        })
            .then((response) => {
                setClients(response.data.clients)
            })
    }, [token])

    async function removeClient(id) {
        let msgType = 'success'
        /*  Modal */
        setModal(false)

        const data = await api.delete(`/client/${id}`, {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`,
            },
        }).then((response) => {
            const updatedClients = clients.filter((client) => client._id != id)
            setClients(updatedClients)
            return response.data
        }).catch((err) => {

            msgType = 'error'
            return err.response.data
        })

        setFlashMessage(data.message, msgType)

    }



    return (
        <section className='default-container-m1'>
            <h1 className='default-title-m1'> Clientes</h1>
            <Link className="link-default" to="/client/new"> <div className="bt-model-3 bt-width-m2">Cadastrar</div></Link>
            <form >
                <div className='bt-width-m1'>
                    <Input

                        
                        type="text"
                        name="search"
                        placeholder="Buscar"
                        handleOnChange={handleChange}

                    />
                </div>
            </form>
            
            
            <div className='List'>
                {/* Search */}
                {search.length > 0 ?
                    clientSearch.map((client) => (

                        <div className='ListRow' key={client._id}>
                            <div className='bold'>{client.clientName}</div>
                            <div className='action'>
                                <Link className='' to={`/client/edit/${client._id}`}><button className='bt edit' title='Editar'></button></Link>
                                <button className='bt delete' title='Apagar' onClick={() => { handleOnclick(client._id, client.clientName) }}></button>

                            </div>

                        </div>

                    )) : clients.length > 0 ?
                        clients.map((client) => (

                            <div className='ListRow' key={client._id}>
                                <div className='bold'>{client.clientName}</div>
                                <div className='action'>
                                    <Link className='' to={`/client/edit/${client._id}`}><button className='bt edit' title='Editar'></button></Link>
                                    <button className='bt delete' title='Apagar' onClick={() => { handleOnclick(client._id, client.clientName) }}></button>

                                </div>

                            </div>

                        )) : clients.length === 0 && <p> Não há clientes cadastrados</p>}


            </div>


            {/* Modal */}
            <ConfirmModal
                openModal={modal}
                closeModal={() => setModal(!modal)}
                confirm={removeClient}
                id={id}
                messageModal={'Deseja realmente excluir esse cliente ? '}
                name={name}
                btConfirmStyle={'btDelete'}
                btConfirmValue={'Apagar'}
            />
        </section>
    )
}

export default ListClient