import { useState, useEffect } from 'react'

import bus from '../../../utils/bus'

import './message.css'

function Message() {
    const [visibility, setVisibility] = useState(false)
    const [message, setMessage] = useState('')
    
    const [type, setType] = useState('')

    useEffect(() => {

        bus.addListener('flash', ({message, type}) => {
            setVisibility(true)
            setMessage(message)
            setType(type)
            
            setTimeout(() => {
                setVisibility(false)
            }, 10000)
            
        })
    }, [])
    
    return(
        visibility && (
        <div className = {`message ${type}`}>{message}</div>
        )
    )
}

export default Message