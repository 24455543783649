import './formProvider.css'
import Input from '../../../shared/components/form/input'

import { useState, useEffect } from 'react'

import api from '../../../utils/api'
import useFlashMessage from '../../../hooks/useFlashMessage'

function FormProvider({ handleUpdate }) {
    
    
    
    const { setFlashMessage } = useFlashMessage()
    const [token] = useState(localStorage.getItem('token') || '')
    const [modalProviderEdit, setModalProviderEdit] = useState('modal-display-none')
    const [newProvider, setNewProvider] = useState({})
    const [provider, setProvider] = useState()
    const [editProviderData, setEditProviderData] = useState("")
    const [modalProviderDelete, setModalProviderDelete] = useState('modal-display-none')
    const [providerDeleteItem, setProviderDeleteItem] = useState({})

    

    useEffect(() => {
        api.get('/provider/getall', {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`
            }
        })
            .then((response) => {
                setProvider(response.data.provider)
            })
    }, [token])

    function editProvider(item) {

        setModalProviderEdit("modal-display-flex")
        setEditProviderData(item)

    }
    async function updateProvider(item) {
        let msgType = 'success'
        const data = await api.patch(`provider/${item._id}`, item, {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`,
            },
        }).then((response) => {
            return response.data
        }).catch((err) => {
            msgType = 'error'
            return err.response.data
        })
        setFlashMessage(data.message, msgType)
        if (msgType !== 'error') {
            api.get('/provider/getall', {
                headers: {
                    Authorization: `Bearer ${JSON.parse(token)}`
                }
            })
                .then((response) => {
                    setProvider(response.data.provider)
                    handleUpdate(response.data.provider)
                    setModalProviderEdit("modal-display-none")
                })



        }

    }
    const handleChangeProvider = (e) => {

        setEditProviderData({ ...editProviderData, [e.target.name]: e.target.value })
    }
    async function addProvider(provider) {
        let msgType = 'success'



        const data = await api.post('/provider/new', provider, {
            Authorization: `Bearer ${JSON.parse(token)}`
        }).then((response) => {
            return response.data
        })
            .catch((err) => {
                msgType = 'error'
                return err.response.data
            })






        setFlashMessage(data.message, msgType)
       
        if (msgType !== 'error') {
            api.get('/provider/getall', {
                headers: {
                    Authorization: `Bearer ${JSON.parse(token)}`
                }
            })
                .then((response) => {
                    setProvider(response.data.provider)
                    handleUpdate(response.data.provider)
                })

                

        }
    }
    function removeProvider(item) {
        setModalProviderDelete("modal-display-flex")
        setProviderDeleteItem(item)
        console.log("delete item")
        console.log(item)
    }

    async function removeProviderItem(item) {
        let msgType = 'success'
        
        const data = await api.delete(`provider/${providerDeleteItem._id}`, providerDeleteItem, {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`,
            },
        }).then((response) => {
            return response.data
        }).catch((err) => {
            msgType = 'error'
            return err.response.data
        })
        setFlashMessage(data.message, msgType)
        if (msgType !== 'error') {
            api.get('/provider/getall', {
                headers: {
                    Authorization: `Bearer ${JSON.parse(token)}`
                }
            })
                .then((response) => {
                    setProvider(response.data.provider)
                    handleUpdate(response.data.provider)
                    setModalProviderDelete("modal-display-none")
                })



        }

        
    }
    let listProvider
    if(provider){
    listProvider = provider.map((item) =>
        <div key={item._id} className='modal-list-div'> <div>   {item.providerName} </div> <div className=' box-itens-tbody-td-action'>
            <button className='bt edit' title='Editar' onClick={() => { editProvider(item) }} ></button>
            <button className='bt delete' title='Apagar' onClick={() => {removeProvider(item)}}></button>

        </div></div>
    )
    }
    function handleChange(e) {

        setNewProvider({ ...newProvider, [e.target.name]: e.target.value })
    }
    return (
        <section className='modal-section'>
            <div className='modal-provider'>
                <Input
                    type="text"
                    name="providerName"
                    handleOnChange={handleChange}
                    value={newProvider.provider}
                />
                <div className='bt-model-add bt-model-1 bt-width-m2'  onClick={() => { addProvider(newProvider)} } > Adicionar</div>
            </div>

            <div className='modal-list'>
                {listProvider}
            </div>


            {/* CLOTH MODAL EDIT */}
            <div className={`category-modal-background ${modalProviderEdit} `} >
                <div className="category-modal-small">
                    <div className="box-x"> <div onClick={() => { setModalProviderEdit("modal-display-none") }} className="x">X</div></div>
                    <div className='modal-provider'>
                        <Input
                            type="text"
                            name="providerName"
                            handleOnChange={handleChangeProvider}
                            value={editProviderData.providerName}
                        />
                        <div className='bt-model-add bt-model-1 bt-width-m2' onClick={() => { updateProvider(editProviderData) }} > Salvar</div>
                    </div>

                </div>

            </div >

            {/* CONFIRM DELETE MODAL*/}
            <div className={`category-modal-background ${modalProviderDelete} `} >
                <div className="category-modal-small">
                    <div className="box-x"> <div onClick={() => { setModalProviderDelete("modal-display-none") }} className="x">X</div></div>
                    <div className='modal-additive-confirm-delete'>
                        <div className='modal-additive-confirm-delete-text'>Deseja realmente apagar as informações a baixo ?</div>
                        <div className='modal-list-div'><div>   {providerDeleteItem.providerName}  </div> <div></div></div>
                        <div className='bt-model-add bt-model-1 bt-width-m2' onClick={() => { removeProviderItem(providerDeleteItem._id) }} > Apagar</div>
                    </div>

                </div>

            </div >
        </section>
    )
}

export default FormProvider