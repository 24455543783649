import './formCloth.css'
import Input from '../../../shared/components/form/input'
import { Link } from "react-router-dom"
import { useState, useEffect } from 'react'

import api from '../../../utils/api'
import useFlashMessage from '../../../hooks/useFlashMessage'

function FormCloth({ handleUpdate }) {



    const { setFlashMessage } = useFlashMessage()
    const [token] = useState(localStorage.getItem('token') || '')
    const [modalClothEdit, setModalClothEdit] = useState('modal-display-none')
    const [newCloth, setNewCloth] = useState({})
    const [cloth, setCloth] = useState()
    const [editClothData, setEditClothData] = useState("")
    const [modalClothDelete, setModalClothDelete] = useState('modal-display-none')
    const [clothDeleteItem, setClothDeleteItem] = useState({})



    useEffect(() => {
        api.get('/cloth/getall', {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`
            }
        })
            .then((response) => {
                setCloth(response.data.cloth)
            })
    }, [token])

    function editCloth(item) {

        setModalClothEdit("modal-display-flex")
        setEditClothData(item)

    }
    async function updateCloth(item) {
        let msgType = 'success'
        const data = await api.patch(`cloth/${item._id}`, item, {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`,
            },
        }).then((response) => {
            return response.data
        }).catch((err) => {
            msgType = 'error'
            return err.response.data
        })
        setFlashMessage(data.message, msgType)
        if (msgType !== 'error') {
            api.get('/cloth/getall', {
                headers: {
                    Authorization: `Bearer ${JSON.parse(token)}`
                }
            })
                .then((response) => {
                    setCloth(response.data.cloth)
                    handleUpdate(response.data.cloth)
                    setModalClothEdit("modal-display-none")
                })



        }

    }




    const handleChangeCloth = (e) => {

        setEditClothData({ ...editClothData, [e.target.name]: e.target.value })
    }




    async function addCloth(cloth) {
        let msgType = 'success'



        const data = await api.post('/cloth/new', cloth, {
            Authorization: `Bearer ${JSON.parse(token)}`
        }).then((response) => {
            return response.data
        })
            .catch((err) => {
                msgType = 'error'
                return err.response.data
            })
        setFlashMessage(data.message, msgType)

        if (msgType !== 'error') {
            api.get('/cloth/getall', {
                headers: {
                    Authorization: `Bearer ${JSON.parse(token)}`
                }
            })
                .then((response) => {
                    setCloth(response.data.cloth)
                    handleUpdate(response.data.cloth)
                })



        }
    }

    function removeCloth(item) {
        setModalClothDelete("modal-display-flex")
        setClothDeleteItem(item)
        console.log("delete item")
        console.log(item)
    }

    async function removeClothItem(item) {
        let msgType = 'success'
        
        const data = await api.delete(`cloth/${clothDeleteItem._id}`, clothDeleteItem, {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`,
            },
        }).then((response) => {
            return response.data
        }).catch((err) => {
            msgType = 'error'
            return err.response.data
        })
        setFlashMessage(data.message, msgType)
        if (msgType !== 'error') {
            api.get('/cloth/getall', {
                headers: {
                    Authorization: `Bearer ${JSON.parse(token)}`
                }
            })
                .then((response) => {
                    setCloth(response.data.cloth)
                    handleUpdate(response.data.cloth)
                    setModalClothDelete("modal-display-none")
                })



        }

        
    }


    let listCloth
    if (cloth) {
        listCloth = cloth.map((item) =>
            <div key={item._id} className='modal-list-div'> <div>   {item.clothName} </div> <div className=' box-itens-tbody-td-action'>
                <button className='bt edit' title='Editar' onClick={() => { editCloth(item) }} ></button>
                <button className='bt delete' title='Apagar' onClick={() => {removeCloth(item) }}></button>

            </div></div>
        )
    }
    function handleChange(e) {

        setNewCloth({ ...newCloth, [e.target.name]: e.target.value })
    }
    return (
        <section className='modal-section'>
            <div className='modal-cloth'>
                <Input
                    type="text"
                    name="clothName"
                    handleOnChange={handleChange}
                    value={newCloth.cloth}
                />
                <div className='bt-model-add bt-model-1 bt-width-m2' onClick={() => { addCloth(newCloth) }} > Adicionar</div>
            </div>

            <div className='modal-list'>
                {listCloth}
            </div>


            {/* CLOTH MODAL EDIT */}
            <div className={`category-modal-background ${modalClothEdit} `} >
                <div className="category-modal-small">
                    <div className="box-x"> <div onClick={() => { setModalClothEdit("modal-display-none") }} className="x">X</div></div>
                    <div className='modal-cloth'>
                        <Input
                            type="text"
                            name="clothName"
                            handleOnChange={handleChangeCloth}
                            value={editClothData.clothName}
                        />
                        <div className='bt-model-add bt-model-1 bt-width-m2' onClick={() => { updateCloth(editClothData) }} > Salvar</div>
                    </div>

                </div>

            </div >

            {/* CONFIRM DELETE MODAL*/}
            <div className={`category-modal-background ${modalClothDelete} `} >
                <div className="category-modal-small">
                    <div className="box-x"> <div onClick={() => { setModalClothDelete("modal-display-none") }} className="x">X</div></div>
                    <div className='modal-additive-confirm-delete'>
                        <div className='modal-additive-confirm-delete-text'>Deseja realmente apagar as informações a baixo ?</div>
                        <div className='modal-list-div'><div>   {clothDeleteItem.clothName}  </div> <div></div></div>
                        <div className='bt-model-add bt-model-1 bt-width-m2' onClick={() => { removeClothItem(clothDeleteItem._id) }} > Apagar</div>
                    </div>

                </div>

            </div >
        </section>
    )
}

export default FormCloth