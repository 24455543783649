import './formProduct.css'
import Input from '../../../shared/components/form/input'
import { Link } from "react-router-dom"
import { useState, useEffect } from 'react'

import api from '../../../utils/api'
import useFlashMessage from '../../../hooks/useFlashMessage'

function FormProduct({ handleUpdate }) {



    const { setFlashMessage } = useFlashMessage()
    const [token] = useState(localStorage.getItem('token') || '')
    const [modalProductEdit, setModalProductEdit] = useState('modal-display-none')
    const [newProduct, setNewProduct] = useState({})
    const [product, setProduct] = useState()
    const [editProductData, setEditProductData] = useState("")
    const [modalProductDelete, setModalProductDelete] = useState('modal-display-none')
    const [productDeleteItem, setProductDeleteItem] = useState({})



    useEffect(() => {
        api.get('/product/getall', {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`
            }
        })
            .then((response) => {
                setProduct(response.data.product)
            })
    }, [token])

    function editProduct(item) {

        setModalProductEdit("modal-display-flex")
        setEditProductData(item)

    }
    async function updateProduct(item) {
        let msgType = 'success'
        const data = await api.patch(`product/${item._id}`, item, {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`,
            },
        }).then((response) => {
            return response.data
        }).catch((err) => {
            msgType = 'error'
            return err.response.data
        })
        setFlashMessage(data.message, msgType)
        if (msgType !== 'error') {
            api.get('/product/getall', {
                headers: {
                    Authorization: `Bearer ${JSON.parse(token)}`
                }
            })
                .then((response) => {
                    setProduct(response.data.product)
                    handleUpdate(response.data.product)
                    setModalProductEdit("modal-display-none")
                })



        }

    }




    const handleChangeProduct = (e) => {

        setEditProductData({ ...editProductData, [e.target.name]: e.target.value })
    }




    async function addProduct(product) {
        let msgType = 'success'



        const data = await api.post('/product/new', product, {
            Authorization: `Bearer ${JSON.parse(token)}`
        }).then((response) => {
            return response.data
        })
            .catch((err) => {
                msgType = 'error'
                return err.response.data
            })
        setFlashMessage(data.message, msgType)

        if (msgType !== 'error') {
            api.get('/product/getall', {
                headers: {
                    Authorization: `Bearer ${JSON.parse(token)}`
                }
            })
                .then((response) => {
                    setProduct(response.data.product)
                    handleUpdate(response.data.product)
                })



        }
    }

    function removeProduct(item) {
        setModalProductDelete("modal-display-flex")
        setProductDeleteItem(item)
        console.log("delete item")
        console.log(item)
    }

    async function removeProductItem(item) {
        let msgType = 'success'
        
        const data = await api.delete(`product/${productDeleteItem._id}`, productDeleteItem, {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`,
            },
        }).then((response) => {
            return response.data
        }).catch((err) => {
            msgType = 'error'
            return err.response.data
        })
        setFlashMessage(data.message, msgType)
        if (msgType !== 'error') {
            api.get('/product/getall', {
                headers: {
                    Authorization: `Bearer ${JSON.parse(token)}`
                }
            })
                .then((response) => {
                    setProduct(response.data.product)
                    handleUpdate(response.data.product)
                    setModalProductDelete("modal-display-none")
                })



        }

        
    }


    let listProduct
    if (product) {
        listProduct = product.map((item) =>
            <div key={item._id} className='modal-list-div'> <div>   {item.productName} </div> <div className=' box-itens-tbody-td-action'>
                <button className='bt edit' title='Editar' onClick={() => { editProduct(item) }} ></button>
                <button className='bt delete' title='Apagar' onClick={() => {removeProduct(item) }}></button>

            </div></div>
        )
    }
    function handleChange(e) {

        setNewProduct({ ...newProduct, [e.target.name]: e.target.value })
    }
    return (
        <section className='modal-section'>
            <div className='modal-product'>
                <Input
                    type="text"
                    name="productName"
                    handleOnChange={handleChange}
                    value={newProduct.product}
                />
                <div className='bt-model-add bt-model-1 bt-width-m2' onClick={() => { addProduct(newProduct) }} > Adicionar</div>
            </div>

            <div className='modal-list'>
                {listProduct}
            </div>


            {/* CLOTH MODAL EDIT */}
            <div className={`category-modal-background ${modalProductEdit} `} >
                <div className="category-modal-small">
                    <div className="box-x"> <div onClick={() => { setModalProductEdit("modal-display-none") }} className="x">X</div></div>
                    <div className='modal-product'>
                        <Input
                            type="text"
                            name="productName"
                            handleOnChange={handleChangeProduct}
                            value={editProductData.productName}
                        />
                        <div className='bt-model-add bt-model-1 bt-width-m2' onClick={() => { updateProduct(editProductData) }} > Salvar</div>
                    </div>

                </div>

            </div >

            {/* CONFIRM DELETE MODAL*/}
            <div className={`category-modal-background ${modalProductDelete} `} >
                <div className="category-modal-small">
                    <div className="box-x"> <div onClick={() => { setModalProductDelete("modal-display-none") }} className="x">X</div></div>
                    <div className='modal-additive-confirm-delete'>
                        <div className='modal-additive-confirm-delete-text'>Deseja realmente apagar as informações a baixo ?</div>
                        <div className='modal-list-div'><div>   {productDeleteItem.productName}  </div> <div></div></div>
                        <div className='bt-model-add bt-model-1 bt-width-m2' onClick={() => { removeProductItem(productDeleteItem._id) }} > Apagar</div>
                    </div>

                </div>

            </div >
        </section>
    )
}

export default FormProduct