

function NumberToMoney(n){
       
    console.log(n)
    n = n.replace('.', '')
    console.log(n)
    
    if(n.length>2){

        let x = n.slice(0,(n.length-2))
        let y = n.slice(-2)
        
        x = parseInt(x)
        
        n = x + "." + y
                 
    }

    return n
}
export default NumberToMoney