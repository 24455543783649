import { useState } from "react"
import { Link } from "react-router-dom" 



/* form */
import Input from "../../../shared/components/form/input"
import Radio from "../../../shared/components/form/Radio"

function FormClient({ handleSubmit, clientData, btnText, btnCancelLink, title }) {
    const defaultValue = {
        personType: "fisica"
    }

    const [client, setClient] = useState(clientData || defaultValue)
    const [selectOpen, setSelectOpen] = useState('')
    console.log('teste')
    console.log(client)


    function handleChange(e) {

        setClient({ ...client, [e.target.name]: e.target.value })


    }
    //Radio form start
    const handleChangeRadio = (name, value) => {

        setClient({ ...client, [name]: value })
    }
    const options = [
        { value: 'fisica', label: "Pessoa física" },
        { value: 'juridica', label: "Pessoa jurídica" }
    ]
    //Radio form end

    function submit(e) {
        e.preventDefault()

        handleSubmit(client)

    }




    return (
        <div className="container-form-client">
            <div className="center-form-client">
                <h1 className='default-title-m1'>{title}</h1>
                <form onSubmit={submit} className="form-box-client ">
                    <div className="form-client-col-1 form-group">
                        <Input
                            styles="formGroup col400px"
                            text="Cliente"
                            type="text"
                            name="clientName"
                            placeholder="digite o nome do cliente"
                            handleOnChange={handleChange}
                            value={client.clientName || ''}
                        />


                        <Radio
                            options={options}
                            name={"personType"}
                            value={client.personType}
                            defaultValue="fisica"
                            handleOnChange={handleChangeRadio}
                        />


                    </div>

                    {client.personType === "juridica" &&
                        <div className="form-box-cnpj">
                            <div className="input-m-1 form-group">
                                <Input
                                    styles="formGroup col400px"
                                    text="Razão Social"
                                    type="text"
                                    name="corporateName"
                                    placeholder="digite a razão social"
                                    handleOnChange={handleChange}
                                    value={client.corporateName || ''}
                                />
                            </div>
                            <div className="input-m-1 form-group">
                                <Input
                                    styles="formGroup col400px"
                                    text="Nome Fantasia"
                                    type="text"
                                    name="fantasyName"
                                    placeholder=""
                                    handleOnChange={handleChange}
                                    value={client.fantasyName || ''}
                                />
                            </div>
                            <div className="input-m-2 form-group">
                                <Input
                                    styles="formGroup col190px"
                                    text="CNPJ"
                                    type="number"
                                    name="CNPJ"
                                    placeholder=""
                                    handleOnChange={handleChange}
                                    value={client.CNPJ || ''}
                                />
                            </div>
                        </div>
                    }
                    {client.personType === "fisica" &&
                    <div className="form-box-cpf">

                        <div className="input-m-1 form-group">
                            <Input
                                styles="formGroup col400px"
                                text="Nome Completo"
                                type="text"
                                name="completeName"
                                placeholder="digite seu nome completo"
                                handleOnChange={handleChange}
                                value={client.completeName || ''}
                            />
                        </div>

                        <div className="input-m-2 form-group">
                            <Input
                                styles="formGroup col190px"
                                text="CPF"
                                type="number"
                                name="CPF"
                                placeholder=""
                                handleOnChange={handleChange}
                                value={client.CPF || ''}
                            />
                        </div>
                    </div>
}
                    <div className="form-client-col-2">
                        <div className="input-m-1 form-group">

                            <Input
                                styles="formGroup col400px"
                                text="Endereço"
                                type="text"
                                name="address"
                                placeholder=""
                                handleOnChange={handleChange}
                                value={client.address || ''}
                            />
                        </div>
                        <div className="input-m-2 form-group">

                            <Input
                                styles="formGroup col190px"
                                text="Bairro"
                                type="text"
                                name="district"
                                placeholder=""
                                handleOnChange={handleChange}
                                value={client.district || ''}
                            />
                        </div>
                        <div className="input-m-2 form-group">

                            <Input
                                styles="formGroup col190px"
                                text="Número"
                                type="number"
                                name="number"
                                placeholder=""
                                handleOnChange={handleChange}
                                value={client.number || ''}
                            />
                        </div>
                        <div className="input-m-1 form-group">

                            <Input
                                styles="formGroup col190px"
                                text="Complemento"
                                type="text"
                                name="complement"
                                placeholder=""
                                handleOnChange={handleChange}
                                value={client.complement || ''}
                            />
                        </div>
                        <div className="input-m-1 form-group">

                            <Input
                                styles="formGroup col400px"
                                text="Email"
                                type="email"
                                name="email"
                                placeholder=""
                                handleOnChange={handleChange}
                                value={client.email || ''}
                            />
                        </div>
                        <div className="input-m-1 form-group">

                            <Input
                                styles="formGroup col190px"
                                text="Nome do Contato:"
                                type="text"
                                name="contact"
                                placeholder=""
                                handleOnChange={handleChange}
                                value={client.contact || ''}
                            />
                        </div>
                        <div className="input-m-1 form-group">

                            <Input
                                styles="formGroup col190px"
                                text="Telefone"
                                type="tel"
                                name="phone"
                                placeholder=""
                                handleOnChange={handleChange}
                                value={client.phone || ''}
                            />
                        </div>
                    </div>
                    <div className="input-m-1 form-group">
                        <Input
                            styles="formGroup col400px"
                            text="OBS:"
                            type="text"
                            name="obs"
                            placeholder=""
                            handleOnChange={handleChange}
                            value={client.obs || ''}
                        />
                    </div>
                    <div className="form-group-m1 ">
                        <input className="bt-model-add bt-model-1 bt-width-m1" type="submit" value={btnText} />
                        <Link className="link-default" to={btnCancelLink}><div className="bt-model-cancel bt-model-2 bt-width-m1">Cancelar</div></Link>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default FormClient