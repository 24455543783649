import './formAdditive.css'
import Input from '../../../shared/components/form/input'
import { Link } from "react-router-dom"
import { useState, useEffect } from 'react'

import api from '../../../utils/api'
import useFlashMessage from '../../../hooks/useFlashMessage'

//functions
import NumberToMoney from '../../../shared/functions/NumberToMoney'
import RandomString from '../../../shared/functions/RandomString'

function FormAdditive({ handleUpdate, data }) {



    const { setFlashMessage } = useFlashMessage()
    const [token] = useState(localStorage.getItem('token') || '')
    const [modalAdditiveEdit, setModalAdditiveEdit] = useState('modal-display-none')
    const [modalAdditiveDelete, setModalAdditiveDelete] = useState('modal-display-none')
    const [additiveDeleteItem, setAdditiveDeleteItem] = useState({})
    const [newAdditive, setNewAdditive] = useState({})
    const [additive, setAdditive] = useState(data || [])
    const [editAdditiveData, setEditAdditiveData] = useState("")
    
    console.log("additive")
    console.log(additive)
    useEffect(() => {

    })

    function editAdditive(item) {

        setModalAdditiveEdit("modal-display-flex")
        setEditAdditiveData(item)

    }
    function updateEditAdditive(id) {

        
       let data = additive.map((item) => {
            if (id === item._id) {
                return {_id: editAdditiveData._id , additiveName: editAdditiveData.additiveName, price: editAdditiveData.price}
            }else{
                return item
            }
        }
        )
        setAdditive(data)
        setModalAdditiveEdit("modal-display-none")
        handleUpdate(data)
    }
    function removeAdditive(item) {
        setModalAdditiveDelete("modal-display-flex")
        setAdditiveDeleteItem(item)
    }

    function removeAdditiveItem(id) {

        const updatedArray = additive.filter((item) => {
            return item._id !== id
        })
        
        setAdditive(updatedArray)
        handleUpdate(updatedArray)
        setModalAdditiveDelete("modal-display-none")
    }



    function handleChangeMoney(e) {

        let value = NumberToMoney(e.target.value)

        setNewAdditive({ ...newAdditive, [e.target.name]: value })


    }
    function handleChangeEditMoney(e) {

        let value = NumberToMoney(e.target.value)


        setEditAdditiveData({ ...editAdditiveData, [e.target.name]: value })


    }
    const handleChangeAdditive = (e) => {

        setEditAdditiveData({ ...editAdditiveData, [e.target.name]: e.target.value })
    }
    async function addAdditive(data) {

        let id = RandomString(16)
        data = { ...data, _id: id }

        setAdditive([...additive, data])
        /* handleUpdate(additive) */
        let update = [...additive, data]
        handleUpdate(update)




    }


    function handleChange(e) {

        setNewAdditive({ ...newAdditive, [e.target.name]: e.target.value })
    }
    return (
        <section className='modal-section'>
            <div className='modal-additive'>
                <Input
                    text="Nome"
                    type="text"
                    name="additiveName"
                    handleOnChange={handleChange}
                    value={newAdditive.additive}
                />

                <div className='modal-additive-price'>
                    <div className='RS'>R$</div>
                    <Input
                        text="Preço"
                        type="number"
                        name="price"
                        handleOnChange={handleChangeMoney}
                        value={newAdditive.price || ""}
                    />
                </div>
                <div className='bt-model-add bt-model-1 bt-width-m2' onClick={() => { addAdditive(newAdditive) }} > Adicionar</div>
            </div>

            <div className='modal-list'>
                {
                    additive.map((item) =>
                        <div key={item._id} className='modal-list-div'> <div>   {item.additiveName}  </div> <div>R$ {item.price}</div> <div className=' box-itens-tbody-td-action'>
                            <button className='bt edit' title='Editar' onClick={() => { editAdditive(item) }} ></button>
                            <button className='bt delete' title='Apagar' onClick={() => { removeAdditive(item) }}></button>

                        </div></div>
                    )
                }
            </div>


            {/* CLOTH MODAL EDIT */}
            <div className={`category-modal-background ${modalAdditiveEdit} `} >
                <div className="category-modal-small">
                    <div className="box-x"> <div onClick={() => { setModalAdditiveEdit("modal-display-none") }} className="x">X</div></div>
                    <div className='modal-additive'>
                        <Input
                            type="text"
                            name="additiveName"
                            handleOnChange={handleChangeAdditive}
                            value={editAdditiveData.additiveName}
                        />


                        <div className='modal-additive-price'>
                            <div className='RS'>R$</div>
                            <Input
                                text="Preço"
                                type="number"
                                name="price"
                                handleOnChange={handleChangeEditMoney}
                                value={editAdditiveData.price || ""}
                            />
                        </div>
                        <div className='bt-model-add bt-model-1 bt-width-m2' onClick={() => { updateEditAdditive(editAdditiveData._id) }} > Salvar</div>
                    </div>

                </div>

            </div >
            {/* CONFIRM DELETE MODAL*/}
            <div className={`category-modal-background ${modalAdditiveDelete} `} >
                <div className="category-modal-small">
                    <div className="box-x"> <div onClick={() => { setModalAdditiveDelete("modal-display-none") }} className="x">X</div></div>
                    <div className='modal-additive-confirm-delete'>
                        <div className='modal-additive-confirm-delete-text'>Deseja realmente apagar as informações a baixo ?</div>
                        <div className='modal-list-div'><div>   {additiveDeleteItem.additiveName && additiveDeleteItem.additiveName}  </div> <div>R$ {additiveDeleteItem.price && additiveDeleteItem.price}</div></div>
                        <div className='modal-box-bt-model-1'>          
                        <div className='bt-model-cancel bt-model-1 bt-width-m2' onClick={()=> {setModalAdditiveDelete("modal-display-none") }} > Cancelar</div>
                    
                        <div className='bt-model-add bt-model-1 bt-width-m2' onClick={() => { removeAdditiveItem(additiveDeleteItem._id) }} > Apagar</div>
                   </div>
                    </div>

                </div>

            </div >
        </section>
    )
}

export default FormAdditive