

function Home() {

    return (
        <section className='default-container-m1'>
            <p>Pagina Inicial em construção</p>
            <p>Use o menu superior para navegar</p>
        </section>
    )
}

export default Home