import api from '../../../utils/api'

import { useState , useEffect} from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'

/* Hooks */
import useFlashMessage from '../../../hooks/useFlashMessage'

import FormBudget from '../components/FormBudget'

function EditBudget(){
    const [token] = useState(localStorage.getItem('token') || '')
    const {setFlashMessage} = useFlashMessage()
    const navigate = useNavigate()
    const {id} = useParams()
    const [ budget, setBudget] = useState({})

    useEffect(() => {

        api.get(`/budget/get/${id}`, {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`,
            },
            
        }).then((Response) => {
            console.log(Response.data.budget)
            setBudget(Response.data.budget)
        })
    }, [token, id])

    async function updateBudget(obj) {
        let msgType = 'success'
        
        
        const data = await api.patch(`budget/up/${obj._id}`, obj, {
            headers: {
            Authorization: `Bearer ${JSON.parse(token)}`,
        },
        }).then((response) => {
            
            return response.data
        }).catch((err) => {
            
            msgType= 'error'
            return err.response.data
        })
        setFlashMessage(data.message, msgType)
        if (msgType !== 'error'){
            
            navigate('/budget/list')
            }

    }
    return (
        <section >
        <div className='default-container-m1'>

        {budget.client && (
           
            <FormBudget  budgetData={budget} handleSubmit={updateBudget}/>
       )}
    
    </div>
    </section>
    )

}

export default EditBudget