import './formItem.css'
import Input from '../../../shared/components/form/input'
import Select from 'react-select'
import { useState, useEffect } from 'react'


//functions
import NumberToMoney from '../../../shared/functions/NumberToMoney'
import RandomString from '../../../shared/functions/RandomString'

function FormItemEdit({ handleUpdate, data, id }) {

const newItem2Default = {
    labor:"0.00",
    cost:"0.00",
    inCash:"70",
    instalment: "80",
    percentOfSale: "100",
    unit: {label:"Metro", name:"unit", value:"m"},
    measure:"1",
    sex:{ name:"sex", value: "M", label: "Masculino" },

}
const finalPriceDefault = {
    inCashPrice:"0.00",
    instalmentPrice:"0.00"
}



    const [modalItemEdit, setModalItemEdit] = useState('modal-display-none')
    const [modalItemDelete, setModalItemDelete] = useState('modal-display-none')
    const [itemDeleteItem, setItemDeleteItem] = useState({})
    const [newItem2, setNewItem] = useState(data || {})
    const [finalPrice, setFinalPrice] = useState(newItem2.inCashPrice || finalPriceDefault)
    const [item, setItem] = useState([])
    const [editItemData, setEditItemData] = useState("")
    
    let inCashPrice
    let instalmentPrice
   
useEffect(()=>{

    if(data !== newItem2){
        setNewItem(data)
        
    }
    if(newItem2.measure){
    inCashPrice = finalPriceCalc(newItem2.measure ,newItem2.labor, newItem2.cost, newItem2.inCash, newItem2.percentOfSale)
    if(inCashPrice.toFixed(2) !== parseFloat(finalPrice.inCashPrice).toFixed(2)){
        
        let inCashPrice2 = inCashPrice.toFixed(2)
        inCashPrice2 = ''+ inCashPrice2
       
        setFinalPrice( {...finalPrice, inCashPrice: inCashPrice2})
    }

    instalmentPrice = finalPriceCalc(newItem2.measure ,newItem2.labor, newItem2.cost, newItem2.instalment, newItem2.percentOfSale)
    if(instalmentPrice.toFixed(2) !== parseFloat(finalPrice.instalmentPrice).toFixed(2)){
        
        let instalmentPrice2 = instalmentPrice.toFixed(2)
        instalmentPrice2 = ''+ instalmentPrice2
       
        setFinalPrice( {...finalPrice, instalmentPrice: instalmentPrice2})
    }
    console.log("in cash price")
    console.log(inCashPrice)
}
})

    function editItem(item) {

        setModalItemEdit("modal-display-flex")
        setEditItemData(item)

    }
    function removeItem(item) {
        setModalItemDelete("modal-display-flex")
        setItemDeleteItem(item)
    }

    function removeItem2(id) {

        const updatedArray = item.filter((item) => {
            return item._id !== id
        })
        console.log("updatedArray")
        console.log(updatedArray)
        setItem(updatedArray)
    }



    function handleChange(e) {

        setNewItem({ ...newItem2, [e.target.name]: e.target.value })

    }

    function handleOnSubmit(){
        let data = newItem2
        
        
        data = {...data, inCashPrice: finalPrice.inCashPrice , instalmentPrice: finalPrice.instalmentPrice }
        
        handleUpdate(data)

    }
    const handleChangeItem = (e) => {

        setEditItemData({ ...editItemData, [e.target.name]: e.target.value })
    }
    async function addItem(data) {

        let id = RandomString(16)
        data = { ...data, _id: id }

        setItem([...item, data])
        /* handleUpdate(item) */
        let update = [...item, data]
        handleUpdate(update)




    }
    function handleChangeMoney(e) {

        let value = NumberToMoney(e.target.value)

        setNewItem({ ...newItem2, [e.target.name]: value })


    }

    function finalPriceCalc(measure, labor,cost,percent,percentOfSale){

        let price
        if(newItem2.unit.value === "kg"){
            console.log("é quilooooooooooooooooooooooo")
            price = (((parseFloat(cost)/parseFloat(measure))+ parseFloat(labor))*(1 + parseFloat(percent)/100))*(1 + parseFloat(percentOfSale)/100)
           
        }if(newItem2.unit.value === "m"){
            console.log("é metroooooooooooooooooooooo")
            price = (((parseFloat(cost)*parseFloat(measure))+ parseFloat(labor))*(1 + parseFloat(percent)/100))*(1 + parseFloat(percentOfSale)/100)
           
           /*  price = ((parseFloat(labor) + parseFloat(cost)) *(1 + parseFloat(percent)/100) )*(1 + parseFloat(percentOfSale)/100) */
        }            
        
        console.log("preço final")
        console.log(price)
        
        
        return price

    }

  /*   function handleChange(e) {

        setNewItem({ ...newItem2, [e.target.name]: e.target.value })
    } */
    const handleSelectChange = (selectedOption) => {

        console.log("selectedOption")
        console.log(selectedOption)
        setNewItem({ ...newItem2, [selectedOption.name]: selectedOption })
    }

    const selectStyles = {
        control: (styles) => ({ ...styles, borderColor: "black", borderRadius: "10px", border: "1px solid #9e9e9e", height: "41px" }),
    };
    const optionsSex = [
        { name:"sex", value: "M", label: "Masculino" },
        { name:"sex", value: "F" ,label: "Feminino" },
    ]
    const optionsUnit = [
        { name:"unit", value: "m", label: "Metro" },
        { name:"unit", value: "kg" ,label: "Quilo" },
    ]


    return (
        <section className='modal-section-item'>
            <div className='modal-section-input-item'>
                <div className='input-m-3 form-group'>
                    <Input
                        text="Codigo"
                        type="number"
                        name="itemCode"
                        handleOnChange={handleChange}
                        value={newItem2.itemCode}
                    />
                </div>
                <div className='input-m-1 form-group'>
                    <Input
                        text="Descrição"
                        type="text"
                        name="description"
                        handleOnChange={handleChange}
                        value={newItem2.description}
                    />
                </div>
                <div className='input-m-2 form-group'>
                    <Input
                        text="Cor"
                        type="text"
                        name="color"
                        handleOnChange={handleChange}
                        value={newItem2.color}
                    />
                </div>
                <div className='input-m-3 form-group'>
                    <Input
                        text="Referência"
                        type="text"
                        name="ref"
                        handleOnChange={handleChange}
                        value={newItem2.ref}
                    />
                </div>
                <div className="input-m-3 form-group">
                    <label className="input-label">Sexo:

                    </label>
                    <Select
                        options={optionsSex}
                        styles={selectStyles}
                        onChange={handleSelectChange}
                        value={newItem2.sex || newItem2Default.sex}

                    />
                </div>
                <div className='input-m-3 form-group'>
                    <Input
                        text="Custo"
                        type="number"
                        name="cost"
                        handleOnChange={handleChangeMoney}
                        value={newItem2.cost || "0.00"}
                    />
                </div>

                <div className='input-m-3 form-group'>
                    <Input
                        text="Medida"
                        type="number"
                        name="measure"
                        handleOnChange={handleChange}
                        value={newItem2.measure || ""}
                    />
                </div>

                <div className="input-m-3 form-group">
                    <label className="input-label">Unidade:

                    </label>
                    <Select
                        options={optionsUnit}
                        styles={selectStyles}
                        onChange={handleSelectChange}
                        value={newItem2.unit || newItem2Default.unit}

                    />
                </div>

                <div className='input-m-3 form-group'>
                    <Input
                        text="Mão de Obra"
                        type="number"
                        name="labor"
                        handleOnChange={handleChangeMoney}
                        value={newItem2.labor || "0.00"}
                    />
                </div>
                
                <div className='input-m-3 form-group'>
                    <Input
                        text="% Á Vista"
                        type="number"
                        name="inCash"
                        handleOnChange={handleChange}
                        value={newItem2.inCash || ""}
                    />
                </div>
                <div className='input-m-3 form-group'>
                    <Input
                        text="% Parcelado"
                        type="number"
                        name="instalment"
                        handleOnChange={handleChange}
                        value={newItem2.instalment || ""}
                    />
                </div>
                <div className='input-m-3 form-group'>
                    <Input
                        text="% de venda"
                        type="number"
                        name="percentOfSale"
                        handleOnChange={handleChange}
                        value={newItem2.percentOfSale || ""}
                    />
                </div>
                <div className='div-final-price'>
                <div className='input-m-3 form-group'>
                    <label>Á vista</label>
                    <div className='input-block'> R$ {finalPrice.inCashPrice}</div>
                </div>
                <div className='input-m-3 form-group'>
                    <label>Parcelado</label>
                    <div className='input-block'> R$ {finalPrice.instalmentPrice}</div>
                </div>
                </div>


            </div>

            <div className='form-group-m1 form-group-right '>
                <div className='bt-model-add bt-width-m2 bt-model-2 ' onClick={() => {handleOnSubmit()}}>Salvar</div>
            </div>

            {/* CLOTH MODAL EDIT */}
            <div className={`category-modal-background ${modalItemEdit} `} >
                <div className="category-modal-small">
                    <div className="box-x"> <div onClick={() => { setModalItemEdit("modal-display-none") }} className="x">X</div></div>
                    <div className='modal-item'>
                        <Input
                            type="text"
                            name="itemName"
                            handleOnChange={handleChangeItem}
                            value={editItemData.itemName}
                        />
                        <div className='bt-model-add bt-model-1 bt-width-m2'  > Salvar</div>
                    </div>

                </div>

            </div >
            {/* CONFIRM DELETE MODAL*/}
            <div className={`category-modal-background ${modalItemDelete} `} >
                <div className="category-modal-small">
                    <div className="box-x"> <div onClick={() => { setModalItemDelete("modal-display-none") }} className="x">X</div></div>
                    <div className='modal-item-confirm-delete'>
                        <div>Deseja realmente apagar as informações a baixo ?</div>
                        <div className='modal-list-div'><div>   {itemDeleteItem.itemName && itemDeleteItem.itemName}  </div> <div>R$ {itemDeleteItem.price && itemDeleteItem.price}</div></div>
                        <div className='bt-model-add bt-model-1 bt-width-m2'  > Apagar</div>
                    </div>

                </div>

            </div >
        </section >
    )
}

export default FormItemEdit